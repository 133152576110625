import moment from 'moment'

/**
 * 日期时间格式化函数
 * @param {String} time 需要转换的时间
 * @param {String} [formatStr] 需要转换的时间,默认格式为 'YYYY-MM-DD HH:mm:ss'
 * @returns {String} 已转换的时间字符串
 * */
export function handleTime (time, formatStr) {
  return moment(time).format(formatStr || 'YYYY-MM-DD HH:mm:ss')
}

/**
 * 日期格式化函数
 * @param {String} time 需要转换的时间
 * @param {String} [formatStr] 需要转换的时间,默认格式为 'YYYY-MM-DD'
 * @returns {String} 已转换的时间字符串
 * */
export function handleDate (time, formatStr) {
  return moment(time).format(formatStr || 'YYYY-MM-DD')
}

/**
 * 获取上个月的第一天的日期
 * */
export function getLastMonthFirstDay () {
  const date = new Date()
  const curYear = date.getFullYear()
  const curMonth = date.getMonth() + 1
  const temMonth = curMonth > 1 ? curMonth - 1 : 12
  const temYear = curMonth > 1 ? curYear : curYear - 1
  const month = temMonth >= 10 ? temMonth : '0' + temMonth
  return `${temYear}-${month}-01`
}

/**
 * 获取YYYYMMMDD格式的日期
 * */
export function handleDates (d) {
  return moment(d).format('YYYYMMDD')
}

/**
 * 获取上个月的第一天的日期 YYYYMMDD格式
 * */
export function getLastMonthFirstDays () {
  const date = new Date()
  const curYear = date.getFullYear()
  const curMonth = date.getMonth() + 1
  const temMonth = curMonth > 1 ? curMonth - 1 : 12
  const temYear = curMonth > 1 ? curYear : curYear - 1
  const month = temMonth >= 10 ? temMonth : '0' + temMonth
  return `${temYear}${month}01`
}
export function getLastTwoMonthFirstDay () {
  const date = new Date()
  const curYear = date.getFullYear()
  const curMonth = date.getMonth() + 1
  const temMonth = curMonth - 2 > 0 ? curMonth - 2 : (curMonth - 2) + 12
  const temYear = curMonth <= 2 ? curYear - 1 : curYear
  const month = temMonth >= 10 ? temMonth : '0' + temMonth
  return `${temYear}${month}`
}
export function handleTwoDate (d) {
  const date = new Date()
  const curYear = date.getFullYear()
  const curMonth = date.getMonth() + 1
  const temMonth = curMonth - 2 > 0 ? curMonth - 2 : (curMonth - 2) + 12
  const temYear = curMonth <= 2 ? curYear - 1 : curYear
  const month = temMonth >= 10 ? temMonth : '0' + temMonth
  return `${temYear}-${month}`
}

// 时间格式
export function dateFormat (time) {
  if (time) {
    const zoneDate = new Date(time).toJSON()
    const date = new Date(+new Date(zoneDate) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    return date
  } else {
    return ''
  }
}
// 时间格式2
export function dateSplit (time) {
  if (time) {
    const date = time.split(' ')[0]
    return date
  } else {
    return ''
  }
}
// 转换为万
export function Convert (res) {
  const number = res.toString().split('.')[0].length
  if (number <= 4) {
    return res
  } else if (number <= 7) {
    return Math.round(res / 1000) / 10 + '万'
  } else if (number <= 8) {
    return Math.round(res / 1000000) / 10 + '千万'
  } else {
    return Math.round(res / 10000000) / 10 + '亿'
  }
}

<template>
    <div id="charts1" style="width: 100%; height: 100%;"></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.showCharts1()
    })
  },
  methods: {
    // 渲染charts1
    showCharts1 () {
    // 获取DOM节点
      const chartContainer = document.getElementById('charts1')
      // 初始化ECharts实例
      const myChart = this.$echarts.init(chartContainer)
      const option = {
        backgroundColor: 'rgba(7, 29, 29,0.9)',
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '5%'
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'line',
            symbol: 'circle',
            lineStyle: {
              normal: {
                color: 'rgba(36, 162, 242, 1)'
              }
            },
            itemStyle: {
              color: 'rgba(36, 162, 242, 1)',
              borderColor: 'rgba(36, 162, 242, 1)',
              borderWidth: 1
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(24, 108, 161, 1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(36, 162, 242, 0)'
                    }
                  ],
                  false
                )
              }
            },
            data: ['40', '60', '50', '50', '80', '20']
          }
        ]
      }
      // 渲染图表
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <van-nav-bar
    @click-left="onClickLeft"
    class="zapp-nav-bar"
  >
    <template #left>
      <van-icon v-if="showLeft" name="arrow-left" size="16px" :color="titleColor" />
    </template>
    <template #title>
      <span :style="{color: titleColor}"> {{ title }}</span>
    </template>
    <template #right>
      <span v-if="rTitle" :style="{color: titleColor}" @click="onClickRight">{{ rTitle }}</span>
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  name: 'ZQDTAppNavBar',
  props: {
    showLeft: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    titleColor: {
      type: String,
      default: '#fff'
    },
    rTitle: {
      type: String,
      default: ''
    }
  },
  methods: {
    onClickLeft () {
      this.$emit('back')
    },
    onClickRight () {
      this.$emit('rClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.zapp-nav-bar::v-deep .van-nav-bar__content .van-nav-bar__title.van-ellipsis{
  font-weight: 600;
}
.zapp-nav-bar {
  background: linear-gradient(to bottom, rgb(131, 165, 116), #b6d3b2);
}
</style>

<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <app-loading  v-show="loading" style="position: absolute;z-index: 9999"></app-loading>
    <NavBar v-show="this.$route.meta.showNavBar" :showLeft="!['匿名信', '公开信', '我的', '留言', '大模型'].includes(this.$route.meta.title)" titleColor="#fff" :title="this.$route.meta.title" @back="$router.go(-1)"></NavBar>
    <tabbar ref="mytabbar" v-show="$store.state.isTabbarShow"></tabbar>
    <!-- 路由容器 -->
    <section>
      <!-- <keep-alive>
        <router-view v-if="$route.meta.KeepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.KeepAlive"></router-view> -->
    <transition name="van-fade" mode="in-out">
      <keep-alive>
        <router-view v-if="$route.meta.KeepAlive"></router-view>
      </keep-alive>
    </transition>
    <transition name="van-fade" mode="in-out">
      <router-view v-if="!$route.meta.KeepAlive"></router-view>
    </transition>
    </section>
    <!-- 悬浮按钮 -->
    <div
    v-show="['/otherMessage', '/letter'].includes(this.$route.path)"
    ref="draggable"
     class="draggable-button"
      :style="{'left': elePos.x + 'px', 'top': elePos.y + 'px' }"
      @mousedown="dragStartHandler"
      @touchstart.stop="dragStartHandler"
      @mousemove="draggingHandler"
      @touchmove.stop="draggingHandler"
      @mouseup="dragEndHandler"
      @touchend.stop="dragEndHandler">
      <!-- 气泡弹出框 -->
      <van-popover v-model="showPopover" trigger="click" :actions="actions"  placement="left" @select="selectMenu">
        <template #reference>
          <van-button type="primary"><van-icon name="question-o" size="1.2rem" /></van-button>
        </template>
      </van-popover>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Vant, { Dialog, Toast } from 'vant'

import 'vant/lib/index.css'
import tabbar from '@/components/Tabbar.vue'
import NavBar from '@/components/Navbar'
import draggableMixin from '@/mixins/draggable'
// 用到的全局动画库是animate
import 'animate.css'
Vue.use(Vant) // Vue.component(全局定义了组件)

export default {
  components: {
    tabbar,
    NavBar
  },
  provide () {
    return {
      // reload: this.reload
    }
  },
  mixins: [draggableMixin],
  data () {
    return {
      loading: false,
      actions: [
        { text: '已发邮件', icon: 'orders-o' }
        // { text: '选项二', icon: 'music-o' },
        // { text: '选项三', icon: 'more-o' }
      ],
      showPopover: false,
      isRouterAlive: true
    }
  },
  created () {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 2000)
    const showtab = ['/message', '/otherMessage', '/letter', '/mineCenter', '/model'].includes(this.$route.path)
    if (showtab) {
      this.$store.commit('showTab')
    } else {
      this.$store.commit('hideTab')
    }
    this.$watch('$route.path', (newPath, oldPath) => {
      // 处理路径变化的逻辑
      // 控制tab显示
      // console.log('路径变化：', newPath, oldPath)
      const showtab = ['/message', '/otherMessage', '/letter', '/mineCenter', '/model'].includes(this.$route.path)
      if (showtab) {
        this.$store.commit('showTab')
      } else {
        this.$store.commit('hideTab')
      }
    })
  },
  mounted () {
    this.checkPassword()
    // console.log(this.$refs.mytabbar.$el.offsetHeight)
  },
  methods: {
    // 校验密码是否默认
    checkPassword () {
      const userinfo = JSON.parse(localStorage.getItem('userinfo'))
      if (userinfo) {
        if (userinfo.password === '12345678') {
          Dialog.confirm({
            title: '提示',
            message: '密码为默认密码，是否修改？',
            confirmButtonText: '好的',
            cancelButtonText: '忽略'
          })
            .then(() => {
              this.$router.push({
                path: '/change-password'
              })
            })
            .catch(() => {
            })
        }
      }
    },
    // 点击选项触发事件
    selectMenu (val) {
      const email = JSON.parse(localStorage.getItem('userinfo')).email
      console.log('val：：：+ ', val)
      if (val.text === '已发邮件') {
        if (email === '1877288470@qq.com') {
          this.toSYYJ()
        } else {
          this.toYFYJ()
        }
      }
    },
    // 跳转已发邮件页面
    toYFYJ () {
      this.$router.push({
        path: '/my-email'
      })
    },
    // 跳转所有邮件页面
    toSYYJ () {
      this.$router.push({
        path: '/all-email'
      })
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.slide-left-enter{
 opacity:0.5;
}
.slide-left-leave-to{
 opacity:1;
}
.slide-left-enter-active {
  transition:opacity .1s;
}
.slide-left-leave-active {
//  opacity:0.2;
 transition:opacity .1s;

}

*{
  margin: 0;
  padding: 0;
}
html,body{
  height: 100%;
  background-color: #f4f4f4;
}
ul{
  list-style: none;
}

section{
  // padding-bottom: 2.7222rem;
  // 给router-view套个父元素，父元素加个内边距防止电影图片信息显示不全
}

// body{
//   font-size: 16px;// 定义字体大小
// }
// .box{
// cssrem插件中的cssrem.rootFontSize方法可以修改基准的fontsize
// 可以在setting.json中通过 "cssrem.rootFontSize": 100 修改
//   width: 20.8334rem;
//   height: 10.7156rem;
//   background-color: yellow;
// }
body.modal-open {
  position: fixed;
  width: 100%;
  min-height: 100%;
}
.draggable-button {
  position: fixed;
  opacity: 0.75;
  z-index: 9999;
  ::v-deep .van-button--primary {
    color: #fff;
    background-color: #59bbab;
    border: 1px solid #c2c7c4;
    border-radius: 15px;
    width: 50px;
    height: 50px;
  }
}
</style>

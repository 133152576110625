import { render, staticRenderFns } from "./css-loading.vue?vue&type=template&id=8d8dfc2c&"
var script = {}
import style0 from "./css-loading.vue?vue&type=style&index=0&id=8d8dfc2c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
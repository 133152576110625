<template>
  <div class="sjlq-main">
    <!-- 顶部筛选 -->
    <CommonSearch
      v-model="queryParams.receive_email"
      placeholder="请输入接收邮箱"
      @clickSearch="onRefresh"
      @clickBtn="onClickButton"
    ></CommonSearch>
    <!-- 商机列表 -->
    <div class="sj-list" ref="scrollRef">
      <ViewList
        v-model="listLoading"
        :finished="finished"
        :errorReq="requestError"
        :refreshing="refreshing"
        :isEmpty="isEmpty"
        @loadData="getProjectList"
        @onRefresh="onRefresh"
        @changeRefresh="changeRefresh"
      >
        <VisitItem
          v-for="(item,idx) in list"
          :key="idx"
          :item="item"
          @toDetail="toDetail(item)"
        />
      </ViewList>
    </div>
    <!--查询弹窗 -->
    <CommonSearchAction
      v-model="showSearch"
      @closeSerach="closeSerach"
      @resetQuery="cancelRequire"
      @confirmQuery="confirmRequire"
    >
      <template #default>
        <van-form
          colon
          label-align="left"
          input-align="left"
          label-width="50px"
          class="search-content-form"
        >
          <!-- 状态 -->
          <van-field name="radio" label="状态">
            <template #input>
              <van-radio-group v-model="queryParams.status" direction="horizontal">
                <van-radio name="发送成功">发送成功</van-radio>
                <van-radio name="发送失败">发送失败</van-radio>
                <van-radio name="等待中">等待中</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <!-- 内容 -->
          <van-field
            v-model="queryParams.text"
            label="内容"
            placeholder="请输入"
          />
        </van-form>
      </template>
    </CommonSearchAction>
  </div>
</template>

<script>
import { getSendMailList } from '@/api/email'
import { handleDate } from '@/util/timeUtils'
import CommonSearch from '@/components/CommonSearch'
import ViewList from '@/components/ViewList'
import VisitItem from './module/EmailItem.vue'
import CommonSearchAction from '@/components/CommonSearchAction'

export default {
  name: 'MyEmail',
  components: {
    CommonSearch,
    ViewList,
    VisitItem,
    CommonSearchAction
  },
  data () {
    return {
      email: '',
      menuTitle: '我的已发邮件',
      queryParams: {
        pageSize: 10,
        pageNum: 0,
        receive_email: '',
        text: '',
        status: '',
        email: ''
      },
      // 刷新
      refreshing: false,
      isEmpty: false,
      finished: false,
      listLoading: false,
      requestError: false,
      // 弹窗数据
      showSearch: false,
      // 列表数据
      list: []
    }
  },
  computed: {
  },
  activated () {
    this.$nextTick(() => {
      this.$refs.scrollRef.scrollTop = this.$route.meta.scrollTop
      if (this.needRefresh) {
        // this.$store.commit('bussiness/SET_NEED_REFRESH', false)
        this.onRefresh()
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    from.meta.scrollTop = this.$refs.scrollRef.scrollTop
    next()
  },
  created () {
    this.email = JSON.parse(localStorage.getItem('userinfo')).email
    this.queryParams.email = this.email
  },
  mounted () {
    this.getProjectList()
  },
  methods: {
    // 弹窗重置
    cancelRequire () {
      this.queryParams = {
        pageSize: 10,
        pageNum: 0,
        receive_email: '',
        text: '',
        status: '',
        email: this.email
      }
      // this.showSearch = false
      this.onRefresh()
    },
    // 弹窗查询
    confirmRequire () {
      this.onRefresh()
      this.showSearch = false
    },
    // 监听子组件是否刷新
    changeRefresh (val) {
      this.refreshing = val
    },
    // 刷新
    onRefresh () {
      this.finished = false
      this.queryParams.pageNum = 0
      this.queryParams.pageSize = 10
      this.list = []
      this.getProjectList()
    },
    // 获取列表数据
    getProjectList () {
      this.isEmpty = false
      this.listLoading = true
      // 每次请求pageNum + 1
      this.queryParams.pageNum += 1
      // 处理请求参数
      const params = {}
      Object.keys(this.queryParams).forEach(k => {
        const val = this.queryParams[k]
        if (val !== null && val !== '') {
          params[k] = val
        }
      })
      // 如果上拉刷线,列表置空
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      getSendMailList(params).then(res => {
        if (this.queryParams.pageNum === 1) {
          this.list = []
        }
        this.isEmpty = res.total === 0
        const arr = res.data
        if (
          res.total <=
            this.queryParams.pageSize * this.queryParams.pageNum
        ) { this.finished = true }
        this.list = this.list.concat(arr)
        this.refreshing = false
        this.listLoading = false
      }).catch(() => {
        this.refreshing = false
        this.requestError = true
      }).finally(() => {
        this.listLoading = false
      })
    },
    // 搜索
    onClickButton () {
      this.showSearch = true
    },
    // 关闭弹窗事件
    closeSerach () {
      this.showSearch = false
    },
    // 回显时间
    calcTime (date) {
      return handleDate(date)
    },
    /* 跳转到详情 */
    toDetail (item) {
      this.$router.push({
        path: '/yfyj-detail',
        query: item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.push-state {
  color: #4456fb !important;
  font-weight: bold;
}
.sjlq-main {
  .sj-list {
    height: calc(100vh - 100px);
    // height: calc(100vh - 150px);
    overflow: auto;
    padding-top: 8px;
    background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  }
  ::v-deep {
    .van-search__content--round {
      border-radius: 5px;
    }
    .van-search {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      box-sizing: border-box;
      padding: 10px 12px;
      background-color: #b6d3b2;
    }
  }
}
</style>

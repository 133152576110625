<template>
  <van-pull-refresh class="z-pull-refresh" v-model="pullRefreshing" @refresh="onRefresh">
    <van-list
      v-if="!isEmpty"
      v-model="listLoading"
      :offset="20"
      :finished="finished"
      finished-text="没有更多了"
      @load="load"
      :error.sync="errorReq"
      error-text="请求失败，点击重新加载"
    >
      <div style="padding: 0 10px">
        <slot></slot>
      </div>
    </van-list>
    <van-empty  v-else description="暂无数据" />
  </van-pull-refresh>
</template>

<script>
export default {
  name: 'CommonViewList',
  props: {
    // 双向绑定，是否处于加载状态
    value: {
      type: Boolean,
      default: false
    },
    // 数据是否为空
    isEmpty: {
      type: Boolean,
      default: false
    },
    // 是否已加载完成
    finished: {
      type: Boolean,
      default: false
    },
    // 是否加载失败
    errorReq: {
      type: Boolean,
      default: false
    },
    // 是否处于加载中状态
    refreshing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    listLoading: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    pullRefreshing: {
      get () {
        return this.refreshing
      },
      set (val) {
        this.$emit('changeRefresh', val)
      }
    }
  },
  methods: {
    // 下拉刷新
    onRefresh () {
      this.$emit('onRefresh')
    },
    // 加载数据
    load () {
      this.$emit('loadData')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

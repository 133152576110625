<template>
  <CommonViewItem
   :title="item.fileName"
   :needDetail="true"
   :itemList="boItemList"
   @clickArrow="showVideoDetail"
   >
   <template #btns>
     <!-- <van-button @click="toDetail" style="border-radius: 0 5px 5px 5px;background: #3ba07d;">详情</van-button> -->
     <van-button @click="showVideoDetail" style="border-radius: 3px;background: dodgerblue;">查看</van-button>
     <van-button @click="handleCopy" style="border-radius: 3px;background: #3ba07d;">复制视频链接</van-button>
   </template>
  </CommonViewItem>
 </template>

<script>
import CommonViewItem from '@/components/CommonViewItem'

const itemList = [
  {
    label: '上传时间',
    value: '',
    key: 'created_date'
  },
  {
    label: '尺寸',
    value: '',
    key: 'size'
  },
  {
    label: '描述',
    value: '',
    key: 'desc'
  }
]
export default {
  name: 'VideoItem',
  components: {
    CommonViewItem
  },
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    boItemList () {
      const temArr = itemList
      temArr.forEach(item => {
        if (item.key === 'is_now') {
          item.value = this.item[item.key] === 1 ? '是' : '否'
        } else {
          item.value = this.item[item.key]
        }
      })
      return temArr
    }
  },
  methods: {
    showVideoDetail () {
      this.$emit('showVideoDetail')
    },
    handleCopy () {
      this.$emit('handleCopy')
    }
  }
}
</script>

 <style lang="scss" scoped>
   .allo-title {
     display: flex;
     margin-top: 4px;
     .shu {
       width: 3px;
       height: 14px;
       margin: 3px 0 0 8px;
       background: #3ba07d;
       opacity: 1;
       border-radius: 0 3px 3px 3px;
     }
     .title-right {
       flex: 1;
       display: flex;
       justify-content: space-between;
       &-p1 {
         font-size: 14px;
         font-weight: bold;
         line-height: 20px;
         margin: 0 6px;
         color: #353535;
       }
       &-p2 {
         // color: blue;
         height: 20px;
         font-size: 14px;
         margin: 0 6px;
         font-weight: 500;
         line-height: 20px;
       }
     }
   }
 .allo-item {
   width: 100%;
   background: #ffffff;
   border: 1px solid rgba(112, 112, 112, 0.1686);
   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
   opacity: 1;
   border-radius: 10px;
   margin: 0 0 10px 0;
   position: relative;
   .arrow-img {
     width: 20px;
     height: 20px;
     position: absolute;
     right: 15px;
     top: 40%;
   }
   &-content {
     &-left {
       padding: 5px;
       width: 90%;
       &-span {
         display: flex;
         align-items: center;
         padding: 5px 0;
         .title {
           width: 30%;
           font-size: 12px;
           font-family: Source Han Sans CN;
           font-weight: 400;
           color: #8c8c8c;
           opacity: 1;
           text-align: right;
         }
         .content {
           flex: 1;
           margin-left: 1rem;
           font-size: 12px;
           font-family: Source Han Sans CN;
           font-weight: 400;
           color: #333333;
           opacity: 1;
         }
       }
     }
     &-right {
       width: 10%;
       display: flex;
       align-items: center;
       div {
         margin: 0 auto;
       }
     }
     &-xian {
       width: 100%;
       height: 1px; // border: 1px solid #919191;
       background-color: #707070;
       opacity: 0.2;
       margin: 0 auto;
     }
     &-btn {
       margin: 10px 0 10px 0;
       display: flex;
       justify-content: flex-end;
       font-size: 0;
       ::v-deep .van-button {
         height: 24px;
         vertical-align: middle;
         background: #3ba07d;
         opacity: 1;
         border-radius: 0 3px 3px 3px;
         color: #fff;
         margin-right: 1rem;
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
       }
     }
   }
 }
 </style>

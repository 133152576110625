import Vue from 'vue'
import VueRouter from 'vue-router'

import { Toast } from 'vant'

import Login from '@/views/login'
import MessageTab from '@/views/message'
import MessageTabAdd from '@/views/message/add.vue'
import LetterTab from '@/views/letter'
import OtherMessageTab from '@/views/otherMessage'
import MineCenterTab from '@/views/mineCenter'
import SettingTab from '@/views/setting'
import changePasswordTab from '@/views/changePassword'
import MyEmail from '@/views/myemail'
import AllEmail from '@/views/allemail'
import AllMsg from '@/views/allmsg'
import AllMsgDetail from '@/views/allmsg/model/detail'
import UserManage from '@/views/userManage'
import Video from '@/views/video'
import AddVideo from '@/views/video/model/addVideo.vue'
import VideoList from '@/views/video/model/videoList.vue'

Vue.use(VueRouter)// 注册路由插件,两个全局router-view router-link

//  配置表
const routes = [
  // 留言
  {
    name: 'MessageTab',
    path: '/message',
    component: MessageTab, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      KeepAlive: true,
      showNavBar: true,
      title: '留言',
      scrollTop: 0
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 新增留言
  {
    name: 'MessageTabAdd',
    path: '/message-add',
    component: MessageTabAdd, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '写留言'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 公开信
  {
    name: 'LetterTab',
    path: '/letter',
    component: LetterTab, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '公开信'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 其他留言
  {
    name: 'OtherMessageTab',
    path: '/otherMessage',
    component: OtherMessageTab, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      KeepAlive: true,
      showNavBar: true,
      title: '匿名信'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 个人中心
  {
    name: 'MineCenterTab',
    path: '/mineCenter',
    component: MineCenterTab, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '我的'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 用户管理
  {
    name: 'UserManage',
    path: '/user-manage',
    component: UserManage, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '用户管理'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 视频
  {
    name: 'Video',
    path: '/video',
    component: VideoList, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '视频'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 视频列表
  // {
  //   name: 'VideoList',
  //   path: '/video/list',
  //   component: VideoList, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
  //   meta: {
  //     // KeepAlive: true
  //     showNavBar: true,
  //     title: '视频列表'
  //   },
  //   // 路由独享拦截
  //   beforeEnter: (to, from, next) => {
  //     if (localStorage.getItem('token')) {
  //       next()
  //     } else {
  //       next('/login')
  //     }
  //   }
  // },
  // 上传视频
  {
    name: 'AddVideo',
    path: '/video/add',
    component: AddVideo, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '上传视频'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 设置
  {
    name: 'Setting',
    path: '/setting',
    component: SettingTab, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '设置'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 修改密码
  {
    name: 'ChangePassword',
    path: '/change-password',
    component: changePasswordTab, // 路由懒加载 用到的时候在加载，防止项目过大导致首页卡顿
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '修改密码'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 我的邮件-已发邮件
  {
    name: 'MyEmail',
    path: '/my-email',
    component: MyEmail,
    meta: {
      KeepAlive: true,
      scrollTop: 0,
      showNavBar: true,
      title: '我的已发邮件'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 所有邮件-已发邮件
  {
    name: 'AllEmail',
    path: '/all-email',
    component: AllEmail,
    meta: {
      KeepAlive: true,
      scrollTop: 0,
      showNavBar: true,
      title: '所有已发邮件'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 所有留言
  {
    name: 'AllMsg',
    path: '/all-msg',
    component: AllMsg,
    meta: {
      KeepAlive: true,
      scrollTop: 0,
      showNavBar: true,
      title: '所有留言'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 所有留言详情
  {
    name: 'AllMsgDetail',
    path: '/all-msg-detail',
    component: AllMsgDetail,
    meta: {
      KeepAlive: false,
      scrollTop: 0,
      showNavBar: true,
      title: '留言详情'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 已发邮件详情
  {
    name: 'YfyjDetail',
    path: '/yfyj-detail',
    component: () => import('@/views/allemail/model/detail.vue'),
    meta: {
      showNavBar: true,
      title: '已发邮件详情'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 个人信息
  {
    name: 'UserInfo',
    path: '/user-info',
    component: () => import('@/views/userInfo/index.vue'),
    meta: {
      showNavBar: true,
      title: '个人信息'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 学习通课程
  {
    name: 'Xuexitong',
    path: '/xuexitong',
    component: () => import('@/views/xuexitong/index.vue'),
    meta: {
      showNavBar: true,
      // KeepAlive: true,
      title: '学习通课程'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 学习通课程详情
  {
    name: 'XuexitongDetail',
    path: '/xuexitong/detail',
    component: () => import('@/views/xuexitong/detail.vue'),
    meta: {
      showNavBar: true,
      // KeepAlive: true,
      title: '课程详情'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 备忘录
  {
    name: 'Memorandum',
    path: '/memorandum',
    component: () => import('@/views/memorandum/index.vue'),
    meta: {
      showNavBar: true,
      // KeepAlive: true,
      title: '备忘录'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 新增备忘录
  {
    name: 'AddMemorandum',
    path: '/memorandum-add',
    component: () => import('@/views/memorandum/model/add.vue'),
    meta: {
      showNavBar: true,
      title: '新增备忘录'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 修改备忘录
  {
    name: 'EditMemorandum',
    path: '/memorandum-edit',
    component: () => import('@/views/memorandum/model/edit.vue'),
    meta: {
      showNavBar: true,
      title: '修改备忘录'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 减肥计划
  {
    name: 'WeightLoss',
    path: '/weightloss',
    component: () => import('@/views/weightLoss/index.vue'),
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '减肥计划'
    }
  },
  // 减肥计划详情
  {
    name: 'WeightLossDetail',
    path: '/weightlossdetail',
    component: () => import('@/views/weightLoss/model/detail.vue'),
    meta: {
      // KeepAlive: true
      showNavBar: true,
      title: '减肥计划详情'
    }
  },
  // 对话模型
  {
    name: 'Model',
    path: '/model',
    component: () => import('@/views/model/index.vue'),
    meta: {
      KeepAlive: true,
      scrollTop: 0,
      showNavBar: true,
      title: '大模型'
    },
    // 路由独享拦截
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next()
      } else {
        next('/login')
      }
    }
  },
  // 登录
  {
    path: '/login',
    component: Login
  },
  // 重定向
  {
    path: '*',
    redirect: '/mineCenter'
  }
]
const router = new VueRouter({
  // mode: 'history',
  // 不过，问题来了。由于我们的应用是一个单页的客户端应用，如果没有适当的服务器配置，用户在浏览器中直接访问 https://example.com/user/id，就会得到一个 404 错误。这就丑了。

  // 不用担心：要解决这个问题，你需要做的就是在你的服务器上添加一个简单的回退路由。如果 URL 不匹配任何静态资源，它应提供与你的应用程序中的 index.html 相同的页面。漂亮依旧!
  // mode: 'history' 配置已经被一个更灵活的 history 配置所取代。根据你使用的模式，你必须用适当的函数替换它

  // "history": createWebHistory()
  // "hash": createWebHashHistory()
  // "abstract": createMemoryHistory()

  mode: 'hash',
  // mode: 'history',
  routes
})

// 全局拦截(守卫)

router.beforeEach((to, from, next) => {
  // console.log(to.fullPath)
  // console.log(to)

  if (to.meta.isKerwinRequired) {
    // 判断本地存储中是否有token字段
    if (localStorage.getItem('token')) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }// 将跳转的路由path作为参数，登录成功后跳转到该路由
        // let redirect = decodeURIComponent(this.$route.query.redirect || '/main')
        // this.$router.push({ path: redirect }) // 获取登录成功后要跳转的路由。decodeURIComponent函数编码的 URI 进行解码
      })
      Toast.fail('请先登录!')
    }
  } else {
    next()
  }
})
export default router

<template>
  <div id="charts1Detail" style="width: 100%; height: 100%;"></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.showCharts1Detail()
    })
  },
  methods: {
  // 渲染charts1
    showCharts1Detail () {
      // 获取DOM节点
      const chartContainer = document.getElementById('charts1Detail')
      // 初始化ECharts实例
      const myChart = this.$echarts.init(chartContainer)
      const xaxisData = ['12-22', '12-23', '12-24', '12-25', '12-26', '12-27', '12-28']
      const yaxisData1 = [150, 150, 150, 150, 150, 160, 210]
      const yaxisData2 = [1510, 1150, 150, 1150, 1150, 1610, 120]

      const getSymbolData = (datas) => {
        const arr = []
        for (var i = 0; i < datas.length; i++) {
          arr.push({
            value: datas[i],
            symbolPosition: 'end'
          })
        }
        return arr
      }
      const legendData = {
        新增: true,
        访问: true
      }
      const nameData = Object.keys(legendData)
      const option = {
        backgroundColor: 'rgba(7, 29, 29,0.9)',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor: 'rgba(255,255,255,0.75)',
          extraCssText: 'box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.3);',
          textStyle: {
            fontSize: 14,
            color: '#000'
          },
          formatter: (params) => {
            const item = params[0]
            return item.name + ' : ' + item.value + ' 万元'
          }
        },
        grid: {
          left: 10,
          right: 10,
          top: 60,
          bottom: 20,
          containLabel: true
        },
        legend: {
          top: 20,
          left: 'center',
          show: true,
          selected: legendData
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              interval: 0,
              color: '#CED3D3',
              fontSize: 12
            },
            axisLine: {
              show: true,
              lineStyle: {
                type: 'solid',
                // y轴网格线设置
                color: 'rgba(184, 185, 188, 1)',
                width: 1
              }
            },
            axisTick: {
              show: false
            },
            data: xaxisData
          }
        ],
        yAxis: [
          {
            max: 2250,
            interval: 250,
            type: 'value',

            name: nameData[1],
            nameGap: 30,
            nameTextStyle: {
              color: 'rgba(184, 185, 188, 1)',
              fontWeight: 400,
              fontSize: 14,
              padding: [0, 20, 0, 0]
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'rgba(217, 217, 217, 0.4)'
              }
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              color: 'rgba(184, 185, 188, 1)'
            }
          },
          {
            max: 225,
            interval: 25,
            type: 'value',
            name: nameData[0],
            nameGap: 30,
            nameTextStyle: {
              color: 'rgba(184, 185, 188, 1)',
              fontWeight: 400,
              fontSize: 14,
              padding: [0, 20, 0, 0]
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: 'rgba(217, 217, 217, 0.4)'
              }
            },
            axisLabel: {
              show: true,
              fontSize: 12,
              color: 'rgba(184, 185, 188, 1)'
            }
          }
        ],
        series: [
          {
            type: 'line',
            yAxisIndex: 1, // 指定使用第二个坐标轴
            barWidth: 16,
            label: {
              show: false,
              position: 'top',
              color: '#00A8FF'

            },
            itemStyle: {
              color: '#00A8FF'
            },
            lineStyle: {
              color: '#00A8FF',
              width: 2
            },
            symbol: 'rect',
            symbolSize: 8,
            showBackground: true,
            backgroundStyle: {
              color: '#093531',
              borderColor: '#093531',
              borderWidth: 3
            },
            // itemStyle: {

            // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //   {
            //     offset: 0,
            //     color: 'rgba(55, 232, 217, 1)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(45, 243, 226, 0)'
            //   }
            // ])
            // },
            data: yaxisData1
          },
          // {
          //   type: 'pictorialBar',
          //   yAxisIndex: 1, // 指定使用第二个坐标轴
          //   symbol: 'react',
          //   symbolSize: [24, 2],
          //   symbolOffset: [0, -3],
          //   z: 12,
          //   showBackground: true,
          //   backgroundStyle: {
          //     color: '#093531',
          //     borderColor: '#093531',
          //     borderWidth: 3
          //   },
          //   itemStyle: {
          //     color: '#fff'
          //   },
          //   data: getSymbolData(yaxisData1)
          // },
          {
            type: 'line',
            yAxisIndex: 0, // 指定使用第一个坐标轴
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
              color: '#22e250'
            },
            lineStyle: {
              color: '#22e250',
              width: 2
            },
            data: yaxisData2 // 折线图的数据
          }

        ]
      }
      // 渲染图表
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

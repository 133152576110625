<template>
  <div class="co-itm">
    <div v-if="needTitle" class="itm-title">
      <div class="itm-title-left">
        <slot name="itemIcon">
          <!-- <svg-icon class="z-svg-icon" icon-class="list-item-icon"/> -->
        </slot>
        <slot name="itemTitle">
          <span class="tit" :class="{'text-elli':titleElli}">{{title}}</span>
        </slot>
      </div>
      <div v-if="needDetail" class="itm-title-right" @click="clickArrow">
        <slot name="itemArrow">
          <van-icon name="arrow" size="16" color="#86909c" />
        </slot>
      </div>
    </div>
    <div class="itm-cnt" @click="itemClick">
      <slot name="cnt">
        <div class="itm-cnt-citem" v-for="(item,index) in itemList" :key="index">
          <span class="citem-lable">{{item.label}}</span>
          <span class="citem-value" :class="{'text-elli':titleElli}">{{item.value || '--'}}</span>
        </div>
      </slot>
      <slot name="cntOther"></slot>
    </div>
    <div class="itm-btn">
      <slot name="btns"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonListItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    needTitle: {
      type: Boolean,
      default: true
    },
    needDetail: {
      type: Boolean,
      default: true
    },
    titleElli: {
      type: Boolean,
      default: true
    },
    contentElli: {
      type: Boolean,
      default: true
    },
    itemList: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  methods: {
    clickArrow () {
      this.$emit('clickArrow')
    },
    itemClick () {
      this.$emit('itemClick')
    }
  }
}
</script>

<style lang="scss" scoped>
.text-elli{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.co-itm{
  background-color: rgb(229, 241, 234);
  border-radius: 5px;
  padding: 14px 15px;
  margin-bottom: 10px;
  .itm-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 2px solid #E8E8E8;
    &-left{
      display: flex;
      align-items: center;
      overflow: hidden;
      .z-svg-icon{
        width: 28px;
        height: 28px;
      }
      .tit{
        flex: 1;
        margin-left: 8px;
        font-size: 15px;
        font-weight: 600;
        color: #1D2129;
      }

    }
    &-right{
      padding-left: 15px;
    }
  }
  .itm-cnt{
    padding: 12px 0;
    position: relative;
    &-citem{
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;
      span{
        font-size: 14px;
        font-weight: 400;
      }
      .citem-lable{
        display: inline-block;
        width: 100px;
        color: #7C8084;
      }
      .citem-value{
        flex: 1;
        color: #1D2129;
        word-break: break-all;
      }
    }
  }
  .itm-btn{
    display: flex;
    justify-content: flex-end;
   :deep(.van-button) {
      height: 24px;
      vertical-align: middle;
      // background-color: #03a7fc;
      // border-color: #03a7fc;
      background-color: #3a79fc;
      border-color: #3a79fc;
      color: #fff;
      margin-right: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<template>
  <div class="add-video">
    <div v-if="uploadProgress > 0" style="height: 35px;width: 100vw;line-height: 35px;">
      <van-progress style="margin-top: 10px" :percentage="uploadProgress" />
    </div>
    <van-form ref="userinfo" class="add-clue-form" label-width="50px">
      <van-field
        name="video"
        label="视频"
        class="form-field"
      >
        <template #input>
          <van-uploader
            v-model="fileList"
            action="<your_upload_endpoint>"
            accept="video/*"
            :before-read="beforeRead"
            :after-read="afterRead"
            :max-count="1"
            multiple
            show-upload
            name="file"
            label="上传视频"
          />
        </template>
      </van-field>
      <van-field
      class="form-field"
        v-model="form.fileName"
        name="fileName"
        label="文件名"
        readonly
      />
      <van-field
      class="form-field"
        v-model="form.size"
        name="size"
        label="尺寸(MB)"
        readonly
      />
      <van-field
      class="form-field"
        v-model="form.desc"
        name="desc"
        label="描述"
        :maxlength="500"
        placeholder="请输入"
      />
    </van-form>
    <div class="opera-btn">
      <van-button type="info" block @click="handleSubmit"><van-icon name="checked" />保存</van-button>
    </div>
  </div>
</template>

<script>
import { uploadVideos, addVideo } from '@/api/video'
import { Toast } from 'vant'

export default {
  data () {
    return {
      file: null,
      form: {
        fileName: '',
        size: '',
        desc: ''
      },
      fileList: [],
      uploadProgress: 0 // 新增数据属性，用于存储上传进度
    }
  },
  methods: {
    handleSubmit () {
      if (!this.fileList.length) {
        return Toast.fail({ message: '请上传视频！' })
      }
      if (this.form.desc === '') {
        return Toast.fail({ message: '请填写描述！' })
      }
      const formData = new FormData()
      formData.append('video', this.file.file)

      this.file.status = 'uploading'
      this.file.message = '上传中...'
      Toast.loading({
        message: '上传中',
        duration: 0,
        forbidClick: true
      })

      uploadVideos(formData, this.file.file.name, this.handleUploadProgress)
        .then((res) => {
          this.file.status = 'success'
          this.file.message = '上传成功'
          this.uploadProgress = 0 // 重置进度条
          Toast.clear()

          const data = {
            fileName: res.filename,
            size: this.form.size,
            desc: this.form.desc
          }
          addVideo(data).then(res => {
            console.log('res：：：+ ', res)
            Toast.success({
              message: '上传成功',
              duration: 1000,
              forbidClick: true
            })
            setTimeout(() => {
              this.$router.go(-1)
            }, 1000)
          })
        })
        .catch((e) => {
          this.file.status = 'failed'
          this.file.message = '上传失败'
          this.uploadProgress = 0 // 重置进度条
          Toast.clear()
          Toast.fail({
            message: '上传失败，请重试',
            duration: 1500,
            forbidClick: true
          })
        })
        .finally(() => {
          this.file.message = ''
        })
    },
    beforeRead (file) {
      const isLt50M = file.size / 1024 / 1024 < 50
      const fileSize = (file.size / 1024 / 1024).toFixed(2)
      this.form.size = fileSize
      if (!isLt50M) {
        Toast.fail('当前视频大小为' + fileSize + 'MB, 上传视频不能超过 50MB！')
        return false
      }

      const allowedTypes = ['video/mp4', 'video/avi', 'video/mkv']
      if (!allowedTypes.includes(file.type)) {
        Toast.fail('仅支持 MP4、AVI 和 MKV 格式的视频文件！')
        return false
      }

      return true
    },
    afterRead (file) {
      this.file = file
      this.form.fileName = file.file.name
    },
    handleUploadProgress (event) {
      this.uploadProgress = Math.round((event.loaded * 100) / event.total)
    }
  }
}
</script>
<style lang="scss" scoped>
.add-video {
  height: calc(100vh - 46px)
}
.form-field {
  ::v-deep {
    .van-field__control {
    // display: block;
    // box-sizing: border-box;
    // width: 100%;
    // min-width: 0;
    // margin: 0;
    // padding: 0;
    // color: #323233;
    // line-height: inherit;
    // text-align: left;
    // background-color: transparent;
    // border: 0;
    // resize: none;
    font-family: fangsong;
    }
    .van-field__label {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    flex: none;
    box-sizing: border-box;
    width: 6.2em;
    margin-right: 12px;
    color: #878383;
    text-align: left;
    word-wrap: break-word;
    font-size: 12px;
    font-family: fantasy;
}
  }
}
.textarea {
  ::v-deep {
    .van-field__control {
      border: 2px solid rgb(231, 229, 229);
      border-left: none;
      border-right: none;
      border-radius: 2px;
    }
  }

}
.opera-btn{
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 60px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px 10px 0 0;
  .van-button{
    background-color: $base-color;
    border-color: $base-color;
  }
  ::v-deep {
    .van-button--info {
    color: #fff;
    border-radius: 10px;
}
  }
}
</style>

<template>
  <div class="add-message">
    <!-- loading -->
    <css-loading v-show="loading" style="position: absolute;z-index: 9999"></css-loading>
    <van-form ref="form" label-width="40px" v-model="formData">
        <!-- 昵称 -->
        <van-field
          v-model="formData.name"
          label="昵称"
          name="name"
          placeholder="请输入留言接收人的昵称"
        >
        <template #button>
          <van-button size="mini" type="primary" @click="handleHistory" style="background-color: rgb(22, 149, 149);border-radius: 5px;">历史</van-button>
        </template>
        </van-field>
        <transition name="van-fade">
        <div v-if="showCheck" class="check-pop">
          <div @click="confirmCheck(item)" class="check-item" v-for="item,index in nameList" :key="index">
            {{ item.name }}
          </div>
          <div v-if="!nameList.length" class="check-item">-暂无数据-</div>
        </div>
      </transition>
        <!-- 邮件内容 -->
        <van-field
        class="form-field"
          v-model="formData.text"
          label="内容"
          type="textarea"
          rows="6"
          autosize
          name="text"
          placeholder="请填写留言内容"
          style="margin-top: 10px;"
        />
      </van-form>
      <div class="btn-area">
      <van-button type="default" icon="replay" @click="handleReset" style="width: calc(50vw - 20px);border-radius: 5px;">重置</van-button>
      <van-button type="info" icon="guide-o" @click="handleAdd" style="width: calc(50vw - 20px);border-radius: 5px;background-color: rgb(113, 160, 125);">发送</van-button>
    </div>
  </div>
</template>

<script>
import { postMsg, getUniqueNamesByEmail } from '@/api/message'
import { Toast, Dialog } from 'vant'
export default {
  data () {
    return {
      nameList: [],
      showCheck: false,
      loading: false,
      formData: {
        name: '',
        text: ''
      }
    }
  },
  computed: {
  },
  created () {

  },
  methods: {
    // 获取历史输入昵称
    getHistoryName () {
      const params = {
        email: JSON.parse(localStorage.getItem('userinfo')).email
      }
      getUniqueNamesByEmail(params).then(res => {
        console.log('res：：：+ ', res)
        this.nameList = res || []
      })
    },
    // 确认选择历史
    confirmCheck (item) {
      this.formData.name = item.name
      this.showCheck = false
    },
    // 查看输入历史
    handleHistory () {
      if (this.showCheck === false) {
        this.getHistoryName()
        this.showCheck = true
      } else {
        this.showCheck = false
      }
    },
    // 查询按钮点击
    handleAdd () {
      if (this.formData.name === '') {
        return Toast.fail('昵称不可为空!')
      }
      if (this.formData.text === '') {
        return Toast.fail('内容不可为空!')
      }
      if (JSON.parse(localStorage.getItem('userinfo')).address === '') {
        Dialog.confirm({
          title: '提示',
          message: '当前地区信息为空，是否前往个人信息页面获取补充？',
          confirmButtonText: '好的',
          cancelButtonText: '忽略'
        }).then(() => {
          this.$router.push({
            path: '/user-info'
          })
        }).catch(() => {
          this.loading = true
          const data = {
            ...this.formData,
            public: '1',
            location: JSON.parse(localStorage.getItem('userinfo')).address || '',
            delocation: JSON.parse(localStorage.getItem('userinfo')).addressdetail || '',
            email: JSON.parse(localStorage.getItem('userinfo')).email
          }
          postMsg(data).then(res => {
            this.messages = res
            Toast.success('发送成功!')
            this.$router.go(-1)
          }).finally(() => {
            this.loading = false
          })
        })
        return
      }
      this.loading = true
      const data = {
        ...this.formData,
        public: '1',
        location: JSON.parse(localStorage.getItem('userinfo')).address || '',
        delocation: JSON.parse(localStorage.getItem('userinfo')).addressdetail || '',
        email: JSON.parse(localStorage.getItem('userinfo')).email
      }
      postMsg(data).then(res => {
        this.messages = res
        Toast.success('发送成功!')
        setTimeout(() => {
          this.$router.go(-1)
        }, 500)
      }).finally(() => {
        this.loading = false
      })
    },
    // 重置按钮点击
    handleReset () {
      this.formData.name = ''
      this.formData.text = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.add-message {
  height: calc(100vh - 46px);
  overflow-y: auto;
  width: 100vw;
  background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  padding: 0 10px;
  ::v-deep {
    .van-cell {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 16px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      border-radius: 5px;
    }
  }
  .btn-area{
    width: calc(100vw - 20px);
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .message-area {
    width: calc(100vw - 20px);
    height: calc(100vh - 240px);
    background-color: rgb(245, 245, 245);
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    overflow-y: auto;
    .message-item {
      width: calc(100vw - 40px);
      min-height: 40px;
      background-color: rgb(220, 232, 220);
      padding: 5px;
      margin-bottom: 10px;
      border-radius: 2px;
      .item-content {
        min-height: 25px;
        width: calc(100vw - 50px);
        font-size: 14px;
        font-family: 'fangsong';
        line-height: 25px;
        margin-bottom: 5px;
      }
      .item-bottom {
        height: 30px;
        line-height: 30px;
        width: calc(100vw - 50px);
        font-size: 12px;
        font-family: 'kaiti';
        border-top: 1px solid rgb(181, 181, 181);
      }
    }
  }
}
.check-pop {
  background-color: #fff;
  border-radius: 5px;
  width: calc(100vw - 20px);
  min-height: 40px;
  max-height: 160px;
  overflow-y: auto;
  z-index: 9999;
  position: absolute;
  margin-top: 10px;
  padding: 0 5px;
  border: 1px solid rgb(192, 192, 192);
  .check-item {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: rgb(255, 255, 255);
    padding: 0 5px;
    border-bottom: 1px solid rgb(215, 215, 215);
    font-family: 'fangsong';
    color: #323233;
    font-size: 14px;
  }
}
</style>

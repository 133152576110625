<template>
  <div class="sjlq-main">
    <!-- 顶部筛选 -->
    <CommonSearch
      v-model="queryParams.keyword"
      placeholder="请输入关键字"
      :showNum="true"
      :total="total"
      @clickSearch="onRefresh"
    ></CommonSearch>
    <!-- 商机列表 -->
    <div class="sj-list" ref="scrollRef">
      <ViewList
        v-model="listLoading"
        :finished="finished"
        :errorReq="requestError"
        :refreshing="refreshing"
        :isEmpty="isEmpty"
        @loadData="getProjectList"
        @onRefresh="onRefresh"
        @changeRefresh="changeRefresh"
      >
        <VideoItem
          v-for="(item,idx) in list"
          :key="idx"
          :item="item"
          @showVideoDetail="showVideoDetail(item)"
          @handleCopy="handleCopy('https://www.sweek.online/api/preview/videos/' + item.fileName)"
        />
      </ViewList>
    </div>
    <!-- 显示详情弹窗 -->
    <van-popup v-model="showDetail" class="pop" @close="closeDetail" :close-on-click-overlay="false">
      <div class="pop-top">
        <Video ref="video" :src="videoSrc" :second="0.5" />
      </div>
      <div class="pop-btn">
        <van-button type="info" @click="closeDetail">关闭</van-button>
      </div>
    </van-popup>
    <!-- 悬浮按钮 -->
    <div class="add-btn" @click="toAddVideo">
      <van-icon name="plus" />
    </div>
  </div>
</template>

<script>
import { getVideoList } from '@/api/video'
import { handleDate } from '@/util/timeUtils'
import CommonSearch from '@/components/CommonSearch'
import ViewList from '@/components/ViewList'
import VideoItem from '../module/videoItem.vue'

import Video from '@/components/videoModel/video.vue'
import { Toast, Dialog } from 'vant'
export default {
  name: 'VideoList',
  components: {
    CommonSearch,
    ViewList,
    VideoItem,
    Video
  },
  data () {
    return {
      videoSrc: '',
      showDetail: false,
      queryParams: {
        pageSize: 10,
        pageNum: 0,
        keyword: '',
        sex: '',
        role: '',
        email: ''
      },
      // 刷新
      refreshing: false,
      isEmpty: false,
      finished: false,
      listLoading: false,
      requestError: false,
      // 弹窗数据
      showSearch: false,
      // 列表数据
      list: [],
      total: 0
    }
  },
  computed: {
  },
  activated () {
    this.$nextTick(() => {
      this.$refs.scrollRef.scrollTop = this.$route.meta.scrollTop
      if (this.needRefresh) {
        // this.$store.commit('bussiness/SET_NEED_REFRESH', false)
        this.onRefresh()
      }
    })
  },
  beforeRouteLeave (to, from, next) {
    from.meta.scrollTop = this.$refs.scrollRef.scrollTop
    next()
  },
  mounted () {
    this.getProjectList()
  },
  methods: {
    toAddVideo () {
      this.$router.push('/video/add')
    },
    showVideoDetail (item) {
      this.showDetail = true
      this.videoSrc = 'https://www.sweek.online/api/preview/videos/' + item.fileName
    },
    // 关闭视频详情
    closeDetail () {
      this.$refs.video.onStop()
      this.showDetail = false
    },
    // 复制文本的方法
    handleCopy (text) {
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      Toast.success({
        message: '文本已成功复制到剪贴板'
      })
    },
    // 弹窗重置
    cancelRequire () {
      this.queryParams = this.$options.data().queryParams
      // this.showSearch = false
      this.onRefresh()
    },
    // 弹窗查询
    confirmRequire () {
      this.onRefresh()
      this.showSearch = false
    },
    // 监听子组件是否刷新
    changeRefresh (val) {
      this.refreshing = val
    },
    // 刷新
    onRefresh () {
      this.finished = false
      this.queryParams.pageNum = 0
      this.queryParams.pageSize = 10
      this.list = []
      this.getProjectList()
    },
    // 时间格式化
    formatISOTime (isoString) {
      const date = new Date(isoString)
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const day = String(date.getDate()).padStart(2, '0')
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      const seconds = String(date.getSeconds()).padStart(2, '0')

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    },
    // 获取列表数据
    getProjectList () {
      this.isEmpty = false
      this.listLoading = true
      // 每次请求pageNum + 1
      this.queryParams.pageNum += 1
      // 处理请求参数
      const params = {}
      Object.keys(this.queryParams).forEach(k => {
        const val = this.queryParams[k]
        if (val !== null && val !== '') {
          params[k] = val
        }
      })
      // 如果上拉刷线,列表置空
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      getVideoList(params).then(res => {
        if (this.queryParams.pageNum === 1) {
          this.list = []
        }
        this.isEmpty = res.total === 0
        this.total = res.total
        res.data.forEach(item => {
          item.created_date = this.formatISOTime(item.created_date)
        })
        const arr = res.data
        if (
          res.total <=
            this.queryParams.pageSize * this.queryParams.pageNum
        ) { this.finished = true }
        this.list = this.list.concat(arr)
        this.refreshing = false
        this.listLoading = false
      }).catch(() => {
        this.refreshing = false
        this.requestError = true
      }).finally(() => {
        this.listLoading = false
      })
    },
    // 关闭弹窗事件
    closeSerach () {
      this.showSearch = false
    },
    // 回显时间
    calcTime (date) {
      return handleDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
.push-state {
  color: #4456fb !important;
  font-weight: bold;
}
.sjlq-main {
  .sj-list {
    height: calc(100vh - 100px);
    // height: calc(100vh - 150px);
    overflow: auto;
    padding-top: 8px;
    background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  }
  ::v-deep {
    .van-search__content--round {
      border-radius: 5px;
    }
    .van-search {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      box-sizing: border-box;
      padding: 10px 12px;
      background-color: #b6d3b2;
    }
  }
}
.video-list {
  width: 100vw;
  height: calc( 100vh - 48px);
  background-color: #e8e8e8;
  overflow-y: auto;
  padding-top: 5px;
  &-item {
    width: calc( 100vw - 20px );
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    height: 130px;
    font-size: 12px;
    padding: 5px;
    line-height: 20px;
    background-color: #ffffff;
    &-title {
      border-bottom: 1px solid #ccc;
      font-family: 'fangsong';
      font-weight: bold;
      height: 30px;
      line-height: 30px;
      overflow-x: hidden;
      overflow-y: hidden;
      word-break: break-all;
      font-size: 14px;
    }
    &-content {
      font-family: 'kaiti';
      color: gray;
      overflow-y: auto;
    }
  }
}
.pop {
  height: 300px;
  width: 90vw;
  border-radius: 15px;
  background-color: rgb(240, 255, 244);
  .pop-top {
    width: 100%;
    height: 240px;
    line-height: 25px;
    font-size: 14px;
    color: gray;
    // padding: 10px;
    overflow-y: auto;
    font-family: fangsong;
    border: 1px solid gray;
    border-radius: 15px;
  }
  .pop-btn {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ::v-deep {
      .van-button {
        height: 40px;
        width: 105px;
        padding: 0 8px;
        font-size: 16px;
        border-radius: 10px;
        background-color: #a4c7b3;
        border: 1px solid rgb(157, 197, 170);
      }
    }
  }
}
.copy-btn {
  width: 90px;
  text-align: center;
  padding: 0 5px;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: rgb(240, 255, 244)
}
.copy-btn:active {
  background-color: #51aa94;
}
.add-btn {
  position: absolute;
  bottom: 100px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: rgba(51, 129, 86, 0.6);
  border-radius: 10px;
  text-align: center;
  line-height: 50px;

}
.add-btn:active {
  background-color: rgba(158, 205, 188, 0.8);
}
</style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div id="charts3" style="width: 100%; height: 100%;"></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.showCharts3()
    })
  },
  methods: {
    // 渲染charts1
    showCharts3 () {
    // 获取DOM节点
      const chartContainer = document.getElementById('charts3')
      // 初始化ECharts实例
      const myChart = this.$echarts.init(chartContainer)
      // eslint-disable-next-line vue/no-mutating-props
      const datas = this.value.reverse()
      datas.forEach(item => {
        item.percent = ((item.finished / item.total) * 100).toFixed(0)
        if (item.name.length > 3) {
          item.name = item.name.slice(0, 3) + '..'
        }
      })
      const yData = datas.map(item => item.name)
      const zData = datas.map(item => item.finished)
      const pData = datas.map(item => item.percent)
      // 设置等长的背景柱状图
      const maxData = new Array(yData.length).fill(100)
      const option = {
        backgroundColor: 'rgba(7, 29, 29,0.9)',
        grid: {
          left: '20%',
          right: '20%',
          bottom: '5%',
          top: '5%',
          containLabel: false
        },
        xAxis: [
          {
            show: true
          },
          {
            show: false,
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            show: true,
            data: yData,
            position: 'left',
            axisLabel: {
              lineHeight: 0,
              verticalAlign: 'bottom',
              fontSize: 10,
              color: '#229453',
              formatter: '{value}'
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          {
            show: true,
            data: zData,
            offset: 5,
            position: 'right',
            axisLabel: {
              lineHeight: 0,
              verticalAlign: 'bottom',
              fontSize: 10,
              color: '#19E5E6',
              formatter: '{value}'
            },
            axisLine: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '进度',
            show: true,
            type: 'bar',
            barGap: '-100%',
            xAxisIndex: 1,
            barWidth: 8,
            itemStyle: {
              borderRadius: 3,
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: '#0A7782' // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: '#81EFF3' // 0% 处的颜色
                  }
                ],
                global: false // 缺省为 false
              }
            },
            label: {
              show: true,
              position: 'insideRight',
              formatter: '',
              // formatter: '{c}%',
              offset: [-10, 2],
              color: '#fff'
            },
            labelLine: {
              show: false
            },
            z: 2,
            data: pData,
            animationDelay: 1000,
            animationDuration: 1000
          },
          {
            name: '百分比',
            z: 1,
            show: true,
            type: 'bar',
            xAxisIndex: 1,
            barGap: '-100%',
            barWidth: 10,
            itemStyle: {
              borderRadius: 1,
              color: 'rgba(13, 55, 78, 1)'
            },
            label: {
              show: false
            },
            data: maxData
          }
        ]
      }

      // 渲染图表
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div class="sp-mult">
    <div class="tag-list">
      <div
        v-for="item in columns"
        :key="item.value"
        class="tag-list-item"
        :class="{'tag-active': selReult === item[valueId]}"
        @click="handleSelect(item[valueId])"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialSinglePicker',
  props: {
    value: {
      type: String || Number,
      default: ''
    },
    valueId: {
      type: String,
      default: 'value'
    },
    columns: {
      type: Array,
      default () {
        return []
      }
    },
    canCancel: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    selReult: {
      set (val) {
        this.$emit('input', val)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    handleSelect (value) {
      const temResult = this.canCancel ? (this.selReult === value ? '' : value) : value
      this.$emit('input', temResult)
    }
  }
}
</script>

<style lang="scss" scoped>
.sp-mult{
  width: 100%;
  padding-top:0px;

  .tag-title{
    height: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #1D2129;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .tag-list{
    display: flex;
    flex-wrap: wrap;
    &-item{
      width: 80px;
      padding: 0 6px;
      height: 30px;
      background: #FBFBFB;
      border-radius: 4px;
      border: 1px solid #EFEFEF;
      display: flex;
      align-items: center;
      justify-content: center;
      /* line-height: 32px; */
      span{
        font-size: 12px;
        font-weight: 400;
        color: #666666;
      }
    }
    .w-auto{
      width: auto;
      padding: 0 6px;
    }
    &-item.tag-active{
      position: relative;
      background: rgba(34,103,248,0.1);
      border: 1px solid #60a181;
      span{
        color: #60a181;
      }
      &::before{
        content:'';
        display: inline-block;
        z-index: 2;
        position: absolute;
        top: 1px;
        right: 2px;
        width: 4px;
        height: 6px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
      &::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0; height: 0;
        border-color: transparent #60a181 ; /*上下颜色 左右颜色*/
        border-width: 0 16px 16px 0;
        border-style: solid;
      }
    }
    &-item:nth-child(n+2){
      margin-left: 8px;
      margin-bottom: 10px;
    }
    .no-ml{
      margin-left: 0px;
    }
  }
}
</style>

import request from '@/util/request'

// 获取留言根据昵称
export function getMsgByName (params) {
  return request({
    url: '/msg',
    method: 'get',
    params
  })
}

// 根据邮箱查询对应的所有唯一昵称
export function getUniqueNamesByEmail (params) {
  return request({
    url: '/getUniqueNamesByEmail',
    method: 'get',
    params
  })
}

// 获取所有留言根据关键字
export function getAllMsg (params) {
  return request({
    url: '/getAllMsg',
    method: 'get',
    params
  })
}

// 写下留言
export function postMsg (data) {
  return request({
    url: '/msg',
    method: 'post',
    data
  })
}

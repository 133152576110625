/**
 * @name: draggable
 * @description: 元素拖拽
 */
// draggableMixin.js

// draggableMixin.js

export default {
  data () {
    return {
      elePos: { x: 330, y: 300 },
      diffOfTarget: { x: 0, y: 0 },
      initialPos: { x: 0, y: 0 }
    }
  },
  mounted () {
    // 获取元素当前位置
    const el = this.$refs.draggable
    // console.log('el：：：+ ', el)
    // const rect = el.getBoundingClientRect()
    // console.log('rect：：：+ ', rect)
    // this.initialPos.x = rect.left
    // this.initialPos.y = rect.top
  },
  methods: {
    dragStartHandler (event) {
      // 计算手指（鼠标）触摸点与拖拽元素左上角的距离
      const touch = event.type === 'touchstart' ? event.touches[0] : event
      this.diffOfTarget.x = touch.clientX - this.elePos.x
      this.diffOfTarget.y = touch.clientY - this.elePos.y

      // 添加 modal-open 类
      document.body.classList.add('modal-open')
    },
    draggingHandler (event) {
      event.preventDefault() // 阻止默认行为，防止页面滚动
      const touch = event.type === 'touchmove' ? event.touches[0] : event
      const newX = touch.clientX - this.diffOfTarget.x
      const newY = touch.clientY - this.diffOfTarget.y
      // 获取屏幕尺寸
      const screenWidth = window.innerWidth
      const screenHeight = window.innerHeight
      // 获取拖拽元素的尺寸
      const el = this.$refs.draggable
      const rect = el.getBoundingClientRect()
      const elWidth = rect.width
      const elHeight = rect.height
      // 检查是否超出屏幕边界，并限制位置在屏幕内
      this.elePos.x = Math.max(0, Math.min(newX, screenWidth - elWidth))
      this.elePos.y = Math.max(0, Math.min(newY, screenHeight - elHeight))
    },
    dragEndHandler () {
      // 移除 modal-open 类
      document.body.classList.remove('modal-open')

      // 如果拖拽元素没有移动，则将其重置到初始位置
      if (this.elePos.x === this.initialPos.x && this.elePos.y === this.initialPos.y) {
        return false
      }
      // 处理拖拽结束后的逻辑，例如保存元素位置等
    }
  }
}

/* 如何使用： 添加以下属性到元素中 */
// :style="{'left': elePos.x + 'px', 'top': elePos.y + 'px' }"
// @mousedown="dragStartHandler"
// @touchstart.stop="dragStartHandler"
// @mousemove="draggingHandler"
// @touchmove.stop="draggingHandler"
// @mouseup="dragEndHandler"
// @touchend.stop="dragEndHandler"

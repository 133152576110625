<template>
  <div id="charts2Detail" style="width: 100%; height: 100%;"></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.showCharts2Detail()
    })
  },
  methods: {
    // 渲染charts2
    showCharts2Detail () {
      // 获取DOM节点
      const chartContainer = document.getElementById('charts2Detail')
      // 初始化ECharts实例
      const myChart = this.$echarts.init(chartContainer)
      const xData = ['12-22', '12-23', '12-24', '12-25', '12-26', '12-27', '12-28']
      const data1 = [1510, 1150, 150, 1150, 1150, 1610, 120]
      const data2 = [150, 150, 150, 150, 150, 160, 210]
      const legendData = {
        余量: true,
        使用: true
      }
      const nameData = Object.keys(legendData)

      const option = {
        backgroundColor: 'rgba(7, 29, 29,0.9)',
        tooltip: {
          axisPointer: {
            type: 'shadow'
          },
          borderWidth: 0
        },
        legend: {
          top: 20,
          left: 'center',
          show: true,
          selected: legendData,
          textStyle: {
            color: '#eee' // 设置图例文字颜色为白色
          }
        },

        grid: {
          left: 10,
          right: 10,
          top: 60,
          bottom: 20,
          containLabel: true
        },
        xAxis: {
          data: xData,
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            interval: Math.ceil(Math.ceil(Math.max(...data1)) / 5),
            max: Math.ceil(Math.ceil(Math.max(...data1)) / 5) * 5,
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(52,78,130,0.2)',
                width: 1,
                type: 'dashed'
              }
            }
          },
          {
            type: 'value',
            interval: Math.ceil(Math.ceil(Math.max(...data2) - (Math.min(...data2) > 0 ? '0' : Math.min(...data2))) / 5),
            max: Math.ceil(Math.ceil(Math.max(...data2)) / 5) * 5,
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(52,78,130,0.2)',
                width: 1,
                type: 'dashed'
              }
            },
            axisLabel: {
              formatter: '{value}'
            }
          }
        ],
        series: [
          {
            name: nameData[0],
            type: 'bar',
            barWidth: '18px',
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(84,115,232,8)'
                },
                {
                  offset: 1,
                  color: 'rgba(24,144,255,0.2)'
                }
              ])
              // shadowColor: '#3C97F2',
              // shadowBlur: 10,

            },
            data: data1
          },
          {
            name: nameData[1],
            type: 'line',
            yAxisIndex: 1,
            showAllSymbol: false, // 显示所有图形。
            symbolSize: 5,
            symbolColor: '#eee',
            symbol: 'rect',
            lineStyle: {
              width: 2,
              color: '#F98C6A'
            },
            itemStyle: {
              color: '#F98C6A',
              shadowColor: '#F98C6A',
              shadowBlur: 10,
              borderColor: '#F98C6A',
              borderWidth: 2
            },
            data: data2
          }
        ]
      }

      // 渲染图表
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

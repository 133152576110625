<template>
  <div class="vant-container">
    <div class="board">
      <p>1.密码长度：通常要求密码长度在一定范围内，比如6到20个字符之间。</p>
      <p>2.包含字母和数字：密码需要同时包含字母和数字。</p>
      <p>3.特殊字符：可选项，密码可以包含特殊字符，如!@#$%^&*等。</p>
    </div>
    <van-form class="form" @submit="onSubmit">
      <van-field
        v-model="oldPassword"
        label="原密码"
        type="password"
        placeholder="请输入原密码"
        required
        clearable
      />
      <van-field
        v-model="newPassword"
        label="新密码"
        type="password"
        placeholder="请输入新密码"
        required
        clearable
        :rules="newPasswordRules"
      />
      <van-field
        v-model="confirmNewPassword"
        label="确认新密码"
        type="password"
        placeholder="请确认新密码"
        required
        clearable
        :rules="confirmNewPasswordRules"
        :error-message="passwordMismatchMessage"
      />
      <div class="bottom-btn">
        <van-button type="primary" style="padding: 0 20px;" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { changePassword } from '@/api/user.js'
export default {
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    }
  },
  computed: {
    passwordMismatchMessage () {
      return this.confirmNewPassword && this.newPassword !== this.confirmNewPassword ? '密码不一致' : ''
    },
    newPasswordRules () {
      return [
        { required: true, message: '请输入新密码', trigger: 'blur' },
        { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' },
        { pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z\W_]{6,20}$/, message: '密码必须包含字母和数字', trigger: 'blur' }
      ]
    },
    confirmNewPasswordRules () {
      return [
        { required: true, message: '请确认新密码', trigger: 'blur' }
      ]
    }
  },
  methods: {
    onSubmit () {
      if (this.oldPassword === this.newPassword) {
        return this.$toast.fail('原密码和新密码重复！')
      }
      if (this.newPassword === this.confirmNewPassword) {
        // 在这里执行密码修改逻辑
        const data = {
          email: JSON.parse(localStorage.getItem('userinfo')).email,
          password: this.oldPassword,
          new_password: this.newPassword
        }
        changePassword(data).then(res => {
          if (res.code === 200) {
            this.$toast.success(res.message)
            this.$router.go(-1)
          } else {
            this.$toast.fail(res.message)
          }
        })
      } else {
        this.$toast.fail('密码不一致，请重新输入！')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 根据 Vant 的样式自定义样式 */
.vant-container {
  width: 100vw;
  height: calc(100vh - 46px);
  background-color: #ebfced;
  overflow: hidden;
  .board {
    width: 100vw;
    height: 200px;
    line-height: 25px;
    font-size: 15px;
    font-family: 'fangsong';
    padding: 10px 20px;
  }
  .form {
    margin: 10px 20px;
    width: calc(100vw - 40px);
    padding: 30px 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #b5d3b1;
    // box-shadow: 2px 2px 5px gray;
    .bottom-btn {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      ::v-deep {
        .van-button--primary {
          color: #fff;
          background-color: #07c160;
          border: 1px solid #07c160;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>

import request from '@/util/request'

// 根据账号或者邮箱获取当前登录人信息
export function getUserInfo (params) {
  return request({
    url: '/getUserInfo',
    method: 'get',
    params
  })
}

// 根据邮箱修改当前登录人信息
export function updateUserInfo (data) {
  return request({
    url: '/updateUserInfo',
    method: 'put',
    data
  })
}

// 根据邮箱修改当前登录人密码
export function changePassword (data) {
  return request({
    url: '/changePassword',
    method: 'post',
    data
  })
}

// 获取用户信息列表
export function getUsersList (params) {
  return request({
    url: '/getUsersList',
    method: 'get',
    params
  })
}

import request from '@/util/request'

// 获取邮箱验证码
export function getEmailCode (params) {
  return request({
    url: '/getEmailCode',
    method: 'get',
    params
  })
}

// 验证邮箱验证码
export function verifyEmailCode (params) {
  return request({
    url: '/verifyEmailCode',
    method: 'get',
    params
  })
}

// 账号注册
export function registerUser (data) {
  return request({
    url: '/registerUser',
    method: 'post',
    data
  })
}

// 邮箱登录
export function loginByEmail (data) {
  return request({
    url: '/loginByEmail',
    method: 'post',
    data
  })
}

// 账号登录
export function loginByUsername (data) {
  return request({
    url: '/loginByUsername',
    method: 'post',
    data
  })
}

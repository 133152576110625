<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div id="charts4" style="width: 100%; height: 100%;"></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {}
  },
  created () {
  },
  mounted () {
    this.$nextTick(() => {
      this.showCharts4()
    })
  },
  methods: {
    // 渲染charts
    showCharts4 () {
    // 获取DOM节点
      const chartContainer = document.getElementById('charts4')
      // 初始化ECharts实例
      const myChart = this.$echarts.init(chartContainer)
      const option = {
        backgroundColor: 'rgba(7, 29, 29,0.9)',
        // tooltip: {
        //   show: true,
        //   trigger: 'item'
        // },

        radar: {
        // shape: 'circle',
          name: {
            textStyle: {
              color: '#fff',
              // backgroundColor: '#999',
              borderRadius: 2,
              padding: [-10, -10]
            }
          },
          axisLine: {
            lineStyle: {
              color: '#1968a0',
              width: 1,
              type: 'solid'
            }
          },

          splitArea: {
            areaStyle: {

              color: ['#0a1044', '#0f2a5e']
            }
          },
          splitLine: {
            lineStyle: {
              color: ['#19669d', '#19669d', '#19669d', '#19669d'],
              width: 1
            }
          },
          indicator: [{
            text: '',
            max: 1500
          }, {
            text: '',
            max: 1500
          }, {
            text: '',
            max: 1500
          }, {
            text: '',
            max: 1500
          }, {
            text: '',
            max: 1500
          }, {
            text: '',
            max: 1500
          }]
        },
        series: [{
          name: '测试',
          type: 'radar',
          symbol: 'circle',
          symbolSize: 1,
          areaStyle: {
            normal: {
              color: 'rgba(40,203,228,0.3)'
            }
          },
          itemStyle: {
            color: 'rgba(40,202,206,1)',
            borderColor: 'rgba(40,202,206,.5)',
            borderWidth: 1
          },

          data: [
            {
              value: this.value,
              name: '测试'
            }]

        }]
      }

      // 渲染图表
      myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>


<template>
  <div class="main-page">
    <!-- 顶部搜索框 -->
    <van-search
      v-model="queryParams.keyword"
      show-action
      :label="'总数:' + total"
      placeholder="请输入关键词"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">查询</div>
      </template>
    </van-search>
    <!-- 公开信列表 -->
    <div class="letter-list">
      <ViewList
        v-model="listLoading"
        :finished="finished"
        :errorReq="requestError"
        :refreshing="refreshing"
        :isEmpty="isEmpty"
        @loadData="getProjectList"
        @onRefresh="onRefresh"
        @changeRefresh="changeRefresh"
      >
        <VisitItem
          v-for="(item,idx) in list"
          :key="idx"
          :item="item"
          @toDetail="toDetail(item)"
        />
      </ViewList>
    </div>
  </div>
</template>
<script>
import { handleDate } from '@/util/timeUtils'
import { getAllLetters } from '@/api/letter.js'

import ViewList from '@/components/ViewList'
import VisitItem from './module/EmailItem.vue'
export default {
  name: 'LetterTab',
  components: {
    ViewList,
    VisitItem
  },
  data () {
    return {
      queryParams: {
        pageSize: 10,
        pageNum: 0,
        keyword: ''
      },
      // 刷新
      refreshing: false,
      isEmpty: false,
      finished: false,
      listLoading: false,
      requestError: false,
      // 弹窗数据
      showSearch: false,
      // 列表数据
      list: [],
      total: 0
    }
  },
  computed: {
  },
  created () {

  },
  methods: {
    onSearch () {
      // this.onRefresh()
    },
    // 监听子组件是否刷新
    changeRefresh (val) {
      this.refreshing = val
    },
    // 刷新
    onRefresh () {
      this.finished = false
      this.queryParams.pageNum = 0
      this.queryParams.pageSize = 10
      this.list = []
      this.getProjectList()
    },
    // 获取列表数据
    getProjectList () {
      this.isEmpty = false
      this.listLoading = true
      // 每次请求pageNum + 1
      this.queryParams.pageNum += 1
      // 处理请求参数
      const params = {}
      Object.keys(this.queryParams).forEach(k => {
        const val = this.queryParams[k]
        if (val !== null && val !== '') {
          params[k] = val
        }
      })
      // 如果上拉刷线,列表置空
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }
      getAllLetters(params).then(res => {
        if (this.queryParams.pageNum === 1) {
          this.list = []
        }
        this.total = res.totalItems
        this.isEmpty = res.totalItems === 0
        const arr = res.data
        if (res.total <= this.queryParams.pageSize * this.queryParams.pageNum) {
          this.finished = true
        }
        this.list = this.list.concat(arr)
        this.refreshing = false
        this.listLoading = false
      }).catch(() => {
        this.refreshing = false
        this.requestError = true
      }).finally(() => {
        this.listLoading = false
      })
    },
    // 回显时间
    calcTime (date) {
      return handleDate(date)
    },
    /* 跳转到详情 */
    toDetail (item) {
      // this.$router.push({
      //   path: '/yfyj-detail',
      //   query: item
      // })
    }
  }
}
</script>
<style lang="scss" scoped>
.main-page {
  height: calc(100vh - 3.622rem);
  overflow-y: auto;
  width: 100vw;
  // background: linear-gradient(to bottom, #648f5f, #b6d3b2);
  background-color: #b6d3b2;
  .letter-list {
    height: calc(100vh - 130px);
    width: 100vw;
    background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
    overflow-y: auto;
    padding-top: 10px;
    .letter-item {
      width: calc(100vw - 20px);
      margin: 0 auto;
      margin-top: 10px;
      background-color: #698e7f;
      border-radius: 10px;
    }
  }
}
.main-page {
  ::v-deep {
    .van-search__label {
    padding: 0px 2px;
    color: #698e7f;
    font-size: 14px;
    line-height: 25px;
    border: 1px solid #628e62;
    height: 25px;
    margin-top: 4px;
    border-radius: 4px;
    background-color: #d9f9e4;
    font-size: 12px;
    }
    .van-search__label:hover {
      background-color: #a3c5a9;
    }
    .van-search {
      // background: linear-gradient(to bottom, rgb(140, 189, 135), rgb(207, 238, 203));
      background: linear-gradient(to bottom, #648f5f, #b6d3b2);
      border-radius: 10px;
      width: calc(100vw - 20px);
      margin-left: 10px;
      margin-top: 5px;
      // border: 1px solid rgb(200, 200, 200);
      box-shadow: 0 0 2px rgb(39, 58, 47);
    }
    .van-search__content {
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      padding-left: 12px;
      background-color: #ffffff;
      border-radius: 2px;
      border-radius: 5px;
    }
  }
}
</style>

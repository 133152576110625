<template>

  <div class="main-page">
    <!-- loading -->
    <css-loading v-show="loading" style="position: absolute;z-index: 9999"></css-loading>
    <!-- 查询表单 -->
    <van-form ref="form" v-model="formData">
      <!-- 昵称 -->
      <van-field
        v-model="formData.name"
        label="昵称"
        name="name"
        placeholder="请输入留言接收人的昵称"
        label-width="50px"
      >
      <template #button>
        <van-button size="mini" type="primary" @click="handleHistory" style="background-color: rgb(22, 149, 149);border-radius: 5px;">历史</van-button>
      </template>
      </van-field>
      <transition name="van-fade">
        <div v-if="showCheck" class="check-pop">
          <div @click="confirmCheck(item)" class="check-item" v-for="item,index in nameList" :key="index">
            {{ item.name }}
          </div>
          <div v-if="!nameList.length" class="check-item">-暂无数据-</div>
        </div>
      </transition>
    </van-form>
    <div class="btn-area">
      <van-button type="default" icon="replay" @click="handleReset" style="width: calc(50vw - 20px);border-radius: 5px;">重置</van-button>
      <van-button type="info" icon="search" @click="handleSearch" style="width: calc(50vw - 20px);border-radius: 5px;background-color: rgb(113, 160, 125);">查询</van-button>
    </div>
    <!-- 查询留言显示 -->
    <div ref="scrollRef" class="message-area">
      <div :class="{ border: item.email==email}" class="message-item" v-for="item,index in messages" :key="index">
        <div class="item-content">
          <span style="background-color: rgb(121, 191, 192);padding: 1px 5px;border-radius: 2px;">{{ index + 1 }}</span>
          {{ item.text }}
        </div>
        <div class="item-bottom">
          <span><van-icon name="location-o" />{{ item.location || '好像在火星' }}</span>
          <span style="float: right;color: gray;">{{ item.date }}</span>
        </div>
      </div>
      <div v-if="!messages.length" style="display: flex;justify-content: center;align-items: center;">
      <div class="bgc-img" style="width: 200px;height: 200px;text-align: center;font-family: 'fangsong';font-size: 15px;padding-top: 100px;">
        -留言数据为空-
      </div>
    </div>
    </div>
    <!-- 悬浮按钮 -->
    <div class="add-btn" @click="toAddMessage">
      <van-icon name="plus" />
    </div>
  </div>
</template>
<script>
import { getMsgByName, getUniqueNamesByEmail } from '@/api/message'
import { Toast } from 'vant'
export default {
  name: 'MessageTab',
  data () {
    return {
      nameList: [],
      showCheck: false,
      loading: false,
      messages: [],
      formData: {
        name: ''
      }
    }
  },
  computed: {
    email () {
      return JSON.parse(localStorage.getItem('userinfo')).email
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$refs.scrollRef.scrollTop = this.$route.meta.scrollTop
    })
  },
  beforeRouteLeave (to, from, next) {
    from.meta.scrollTop = this.$refs.scrollRef.scrollTop
    next()
  },
  created () {

  },
  methods: {
    // 获取历史输入昵称
    getHistoryName () {
      const params = {
        email: JSON.parse(localStorage.getItem('userinfo')).email
      }
      getUniqueNamesByEmail(params).then(res => {
        // console.log('res：：：+ ', res)
        this.nameList = res || []
      })
    },
    // 确认选择历史
    confirmCheck (item) {
      this.formData.name = item.name
      this.showCheck = false
    },
    // 查看输入历史
    handleHistory () {
      if (this.showCheck === false) {
        this.getHistoryName()
        this.showCheck = true
      } else {
        this.showCheck = false
      }
    },
    // 跳转新增留言界面
    toAddMessage () {
      this.$router.push({
        path: '/message-add'
      })
    },
    // 查询按钮点击
    handleSearch () {
      if (this.formData.name === '') {
        return Toast.fail('昵称不可为空!')
      }
      this.loading = true
      getMsgByName(this.formData).then(res => {
        this.messages = res
      }).finally(() => {
        this.loading = false
      })
    },
    // 重置按钮点击
    handleReset () {
      this.formData.name = ''
      this.messages = []
    }
  }
}
</script>
<style lang="scss" scoped>
.main-page {
  height: calc(100vh - 46px);
  overflow-y: auto;
  width: 100vw;
  background: linear-gradient(to bottom, #b6d3b2, rgb(239, 243, 242));
  padding: 0 10px;
  ::v-deep {
    .van-cell {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 10px 16px;
      overflow: hidden;
      color: #323233;
      font-size: 14px;
      line-height: 24px;
      background-color: #fff;
      border-radius: 5px;
    }
  }
  .btn-area{
    width: calc(100vw - 20px);
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .message-area {
    width: calc(100vw - 20px);
    height: calc(100vh - 240px);
    background-color: rgb(245, 245, 245);
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    overflow-y: auto;
    .message-item {
      width: calc(100vw - 40px);
      min-height: 40px;
      background-color: rgb(220, 232, 220);
      padding: 5px;
      margin-bottom: 10px;
      border-radius: 2px;
      .item-content {
        min-height: 25px;
        width: calc(100vw - 50px);
        font-size: 14px;
        font-family: 'fangsong';
        line-height: 25px;
        margin-bottom: 5px;
      }
      .item-bottom {
        height: 30px;
        line-height: 30px;
        width: calc(100vw - 50px);
        font-size: 12px;
        font-family: 'kaiti';
        border-top: 1px solid rgb(181, 181, 181);
      }
    }
  }
}
.add-btn {
  position: absolute;
  bottom: 100px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: rgba(51, 129, 86, 0.6);
  border-radius: 10px;
  text-align: center;
  line-height: 50px;

}
.add-btn:active {
  background-color: rgba(158, 205, 188, 0.8);
}
.bgc-img {
  // background-image: url('../../assets/empty.jpg');
  background-size: cover; /* 让背景铺满整个容器 */
}

.check-pop {
  background-color: #fff;
  border-radius: 5px;
  width: calc(100vw - 20px);
  min-height: 40px;
  max-height: 160px;
  overflow-y: auto;
  z-index: 9999;
  position: absolute;
  margin-top: 10px;
  padding: 0 5px;
  border: 1px solid rgb(192, 192, 192);
  .check-item {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: rgb(255, 255, 255);
    padding: 0 5px;
    border-bottom: 1px solid rgb(215, 215, 215);
    font-family: 'fangsong';
    color: #323233;
    font-size: 14px;
  }
}
.border {
  border: 1px solid rgb(70, 176, 150);
}
</style>

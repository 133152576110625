<template>
  <div class="container">
    <button @click="toUploadVideo" class="set-btn">上传视频</button>
    <button @click="toVideoList" class="set-btn">视频列表</button>
  </div>
</template>

<script>
import { Button, Toast } from 'vant'
export default {
  methods: {
    toVideoList () {
      this.$router.push('/video/list')
    },
    toUploadVideo () {
      this.$router.push('/video/add')
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: calc(100vh - 46px);
  background-color: #ebfced;
  .set-btn {
    width: calc(100vw - 40px);
    margin: 0 20px;
    display: inline-block;
    padding: 10px 20px;
    margin-top: 15px;
    background-color: #68976d;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* 添加阴影效果 */
  }

  .set-btn:active {
    background-color: #aee4c1;
  }
}

</style>

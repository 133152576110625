import axios from 'axios'
import { Notify } from 'vant'
// import { getToken } from '@/utils/auth'
import errorCode from '@/util/errorCode'
import XEUtils from 'xe-utils'
import cache from '@/plugins/cache'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: 'https://www.sweek.online/api',
  // baseURL: 'http://127.0.0.1:3000',
  // 超时
  timeout: 300000
})

// 保存上次请求
let lastRequest
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  // const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  // if (getToken() && !isToken) {
  //   // config.headers.Authorization = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  //   config.headers.Authorization = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJtb2JpbGUiLCJsb2dpbkFjY291bnQiOiJIODYxNjU1OCIsImlzcyI6InR5ZGljLWNoaW5hLWFoLXRlbCIsImFjY2Vzc1Rva2VuVmFsdWUiOiJiOTlmZDU1OTkxZjQ4ZTA5MWUyNmJiZGM0YmZmOTRjNmM4YTMyOWMwYmZhYWFjYTUxYTA1ZjAwN2Y5ZDdlMzQ5NTI2MGVhMDI1YjM4YTljNjFiNWI1ZjNmMTY2MzA1MThlNjc1MmI0MzkzODRmMjM3YjhiMmI0YTQ0ODg2OWY0NTFlZTBkMWExZWFlZTk2YmM4MTJkNGRjNmM0YTY0ODg4MDczYTQ3YTUzODM4ZDRhOTZkMTQ2NDIxNDIwMjAzYzQyYWM5ZjE5YzNiZjJlNTUwYzdkMzc3ODRiZmFiMmFkNGViOTJlYmM2MDkzMTJmN2U2NmQ2YzA0ZTNiMTQ3ZWM1MjAxYjU5ZWM3NDRhMjdhNDg0ZjQxYTE0NzY2M2VkNDgyNjhhZTNhNDAwOTU5NDBiNDg4MmYxZjY3MmUzZTM4YTBhM2QwOGI3Njg1YTgyNWI5YjM2MmNlNTVmMTNmMWM2ZGNmNDM4YjIxMDkxMjY0NjkzNDJiZjk2Mzk1YzgzYjRkODBjMzA4MWEwOTkzMWU1YTVlZjBkYjQ2OGVhOTllYTBiM2EwN2RjNmM0NjM2Mzg5YTM4Yjk1NDE5M2MzNWNiZmU1MjU1YmM5YzI4ZDE3OWRmNjkzMzhlYjgzYzgwMmI3YTAxZmVhN2Y1NjVhMzFlMTE3ODZkZjkxODY5YmQ0OWM3NWNlYTRiMjMzNTJiZWI5NDk2OTk0YzFjOWUzNjIyMGE2YjZhYzM4YWRhODY4Zjk0YTdhZWVmMGRjZDU5OTVkZDVkMjhmYjMzMGRiOTgxNDg4ZTExNGQ5MTA2N2M4NmJhMzJiZjE3NGMxZDBkMzE5ZjYyZjFkMmQ1YjMyYjQ3ODZmNzkwYTg5ZjNjMGNhODc0NWM0MTBmMTgwODkxZDM1MmFhMTU1NjU0Y2YxMzgyMjcxZTI1MzlmMWJmOWEyMDIyYzNiMzI0YjM2MGZlZDNjZWI5Nzg2NTY2YjAwNzJlYjFmOWVkODc4MGI4MDgzMjRhODExNmYxYjUxYzdhMjE4M2RhNjcyMzhjYzhkODYwMGZjMTM3NDQwZjc2MzI5MTE5ZmQ3MDA2ZTAwYzkyNDI1MDYyNGVkN2UyYmIwNDM4ZWMzZmE5ZmY1YmQ5YjE2MGNhM2M2ZTAzMTllNDE5ZDFmMWIzOTRjYjczZDUzMGMwZDZlMjE2NTZhM2U0OWYwMjViNTM1MDdhYWIzNzNkYzUxNzcxY2U4Zjg1OWE5MDAzMmZjYzgwNmRhZTVjNGFjMzRkMTNmNWI4ZmZmNmE5ZGIxNDA5OWJlNDgyYjFjNGM4YjgxNjQyYmRlNWYyZjFhNGFjYmMxZDU5YjA4ZjNhMDgzOTNlYzc2N2I0OWI5M2QyIiwiZXhwIjoxNjY0OTM2Njc4LCJpYXQiOjE2NjIzNDQ2Nzh9.by7KWBD_MWZcqOgv042ky1DJ_nUqD7gOL7_Z4ayEJhc'
  // }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    const url = config.url + '?' + XEUtils.serialize(config.params)
    config.params = {}
    config.url = url
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const sUrl = sessionObj.url // 请求地址
      const sData = sessionObj.data // 请求数据
      const sTime = sessionObj.time // 请求时间
      const interval = 1000 // 间隔时间(ms)，小于此时间视为重复提交
      if (sData === requestObj.data && requestObj.time - sTime < interval && sUrl === requestObj.url) {
        const message = '数据正在处理，请勿重复提交'
        console.warn(`[${sUrl}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  lastRequest = config
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.message || errorCode.default
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code === 500) {
    Notify({ type: 'warning', message: msg })
    return Promise.reject(new Error(msg))
  } else if (code !== 200 && code !== '200') {
    Notify({ type: 'danger', message: msg })
    return Promise.reject(new Error('error'))
  } else {
    return res.data
  }
},
error => {
  console.log('err' + error)
  let { message, response } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = response.data
  }
  if (message !== 'canceled') {
    Notify({ type: 'warning', message: message })
  }
  return Promise.reject(error)
}
)

export default service

<template>
  <div class="verification-code-container">
    <input v-for="(item,index) in codes" :key="index"
        class="verification-code-input"
        :ref="'input'+index"
        type="tel"
        maxlength="1"
        @input="onInput(index, $event)"
        inputmode="numeric"
        pattern="[0-9]*" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      codes: ['', '', '', '']
    }
  },
  methods: {
    onInput (index, event) {
      function updateCode (index, value) {
        this.$set(this.codes, index, value)
        this.$emit('input', this.codes.join(''))
      }
      const { data: key, inputType: code } = event
      const isNumber = /^\d$/.test(key)
      const isBackspace = code === 'deleteContentBackward'

      if (isNumber || isBackspace) {
        updateCode.call(this, index, isNumber ? key : '')

        this.$nextTick(() => {
          if (index < 3 && !isBackspace) {
            this.$refs[`input${index + 1}`][0].focus()
          } else if (index < 4 && index > 0 && isBackspace) {
            this.$refs[`input${index - 1}`][0].focus()
          }
        })
      } else {
        updateCode.call(this, index, '')
      }
    }
  }
}
</script>

<style>
.verification-code-container {
  display: flex;
  width: 280px;
}

.verification-code-input {
  width: 62px;
  height: 50px;
  text-align: center;
  border: none;
  border-bottom: 2px solid rgb(86, 142, 179);
  margin-right: 8px;
  font-size: 30px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: rgb(78, 78, 80);
}
</style>

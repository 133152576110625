<!-- eslint-disable vue/no-mutating-props -->
<template>
  <transition v-if="isShow" name="van-fade">
    <van-popup class="pop" v-model="isShow" :close-on-click-overlay="false">
      <div class="pop-form">
        <slot name="content"></slot>
      </div>
      <div class="pop-btn">
        <slot name="btn"></slot>
      </div>
    </van-popup>
  </transition>
</template>

<script>

export default {
  components: {
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
/* 样式表 */
.pop {
  height: 500px;
  width: 90vw;
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  .pop-top {
    width: 100%;
    height: 0px;
    line-height: 40px;
    font-size: 14px;
    color: #727272;
    text-align: center;
  }
  .pop-form {
    width: 100%;
    height: 440px;
    background-color: rgb(151, 189, 175);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    border-radius: 10px;
    overflow-y: auto;
  }
  .pop-btn {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    ::v-deep {
      .van-button {
        height: 40px;
        width: 125px;
        padding: 0 8px;
        font-size: 16px;
        border-radius: 10px;
        background-color: #648a68;
        color: aliceblue;
      }
    }
  }
}
</style>

import request from '@/util/request'

// 上传视频接口
export function uploadVideos (data, fileName, onUploadProgress) {
  return request({
    url: '/upload/videos',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      'x-filename': fileName
    },
    onUploadProgress
  })
}

// 保存上传视频数据
export function addVideo (data) {
  return request({
    url: '/video',
    method: 'post',
    data
  })
}

// 获取视频数据列表
export function getVideoList (params) {
  return request({
    url: '/video',
    method: 'get',
    params
  })
}

// 删除上传视频数据
export function deleteVideoById (params) {
  return request({
    url: '/video',
    method: 'delete',
    params
  })
}

// 修改上传视频数据
export function updateVideoById (data) {
  return request({
    url: '/video',
    method: 'put',
    data
  })
}

<template>
  <div class="com-search">
    <van-search
      v-model="innnerValue"
      :show-action="showAction"
      shape="round"
      class="allo-search"
      :placeholder="placeholder"
      @search="clickSearch"
    >
      <template #action>
        <div v-if="showNum">
          <van-button size="small" type="primary" style="background-color: rgb(22, 149, 149);border-radius: 5px;">{{ total }}条</van-button>
        </div>
        <div v-else-if="showBtn" @click="onClickButton">
          <van-icon name="exchange" />
          <span>切换</span>
        </div>
        <div v-else @click="onClickButton">
          <van-icon name="filter-o" />
          <span>{{searchTitle}}</span>
        </div>
      </template>
    </van-search>
  </div>
</template>

<script>
export default {
  name: 'CommonSearch',
  props: {
    showBtn: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    showNum: {
      type: Boolean,
      default: false
    },
    showAction: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    searchTitle: {
      type: String,
      default: '筛选'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    innnerValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    clickSearch () {
      this.$emit('clickSearch', this.value)
    },
    onClickButton () {
      this.$emit('clickBtn', this.value)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

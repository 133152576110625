<template>
  <van-action-sheet
    v-model="showSearch"
    description="筛选"
    class="z-search"
    @close="closeSerach"
  >
    <template #description>
      <div class="z-search-header">
        <div class="z-search-header-left" @click="reset">重置</div>
        <div class="z-search-header-content">筛选</div>
        <div class="z-search-header-right" @click="closeSerach">
          <van-icon name="cross" size="20" />
        </div>
      </div>
    </template>
    <div class="z-search-content">
      <slot></slot>
    </div>
    <div class="z-search-btn" v-if="showConfirm">
      <van-button round type="info" block @click="confirm">确定</van-button>
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  name: 'CommonSearchAction',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {}
  },
  computed: {
    showSearch: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    closeSerach () {
      this.$emit('closeSerach')
    },
    reset () {
      this.$emit('resetQuery')
    },
    confirm () {
      this.$emit('confirmQuery')
    }
  }
}
</script>

<style lang="scss" scoped>
.z-search {
  &-header{
    display: flex;
    text-align: center;
    color: rgb(0, 140, 255);
    &-left{
      font-size: 18px;
      letter-spacing:2px;
    }
    &-content {
      color: #000;
      flex: 1;
      font-weight: bold;
      font-size: 18px;
    }
  }
  &-content{
    ::v-deep .van-field__label{
      font-size: 15px;
      font-weight: 500;
      color: #1D2129;
    }
  }
  &-btn{
    margin: 12px 0px;
    padding: 0 15px;
    .van-button{
      height: 44px;
      background-color: #2267F8;
      border-color: #2267F8;
    }
  }
}

.z-search :v-deep .van-action-sheet__description {
  font-size: 16px;
  font-weight: bold;
  color: #353535;
  padding: 15px 16px;
  border: none;
  &::after{
    border: none !important;
  }
}
.z-search  .van-action-sheet__description::after {
    border: none !important;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/util/http'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    reducer: (state) => {
      return {
        cityId: state.cityId,
        cityName: state.cityName
        // 意思是只持久化这两个
      }
    }
  })],

  // state公共状态
  state: {
    cityId: '310100',
    cityName: '上海',
    cinemaList: [],
    isTabbarShow: true
  },
  getters: {
  },
  // 统一管理，被devtools记录状态的修改
  // mutations只能支持同步
  mutations: {
    clearCinemaData (state) {
      state.cinemaList = []
    },
    changeCityName (state, cityName) {
      // console.log(cityName)
      state.cityName = cityName
    },
    changeCityId (state, cityId) {
      state.cityId = cityId
    },
    changeCinemaData (state, data) {
      state.cinemaList = data
    },
    showTab (state) {
      state.isTabbarShow = true
    },
    hideTab (state) {
      state.isTabbarShow = false
    }
  },
  // actions支持异步和同步
  actions: {
    getCinemaData (store, cityId) {
      // return promise对象后才可以在cinemas中用.then
      return http({
        url: `https://m.maizuo.com/gateway?cityId=${cityId}&ticketFlag=1&k=2846594`,
        headers: {
          'X-Host': 'mall.film-ticket.cinema.list'
        }
      }).then(res => {
        // console.log(res.data.data.cinemas)
        store.commit('changeCinemaData', res.data.data.cinemas)
      })
    }
  },
  modules: {
  }
})
// vuex管理保存公共状态：(分散在各个组件内的状态，统一管理)
// 注意:
// vuex默认是管理在内存，一刷新页面，公共状态就丢了。
// vuex持久化-todo

// vuex项目应用
// 1.非父子的通信
// 2.后端数据的缓存快照，减少重复数据请求，减轻服务器压力，提高用户体验

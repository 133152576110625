import request from '@/util/request'

// 定时发送邮件
export function scheduleEmail (data) {
  return request({
    url: '/scheduleEmail',
    method: 'post',
    data
  })
}

// 上传图片接口
export function uploadImages (data) {
  return request({
    url: '/upload',
    method: 'post',
    data
  })
}

// 获取发送邮件列表
export function getSendMailList (params) {
  return request({
    url: '/getSendMailList',
    method: 'get',
    params
  })
}

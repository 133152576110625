import Vue from 'vue'// ES6导入方式
import App from './App.vue'// 导入根组件App
// import App from './PCApp.vue'
import router from './router'
import store from './store'
// 引入样式
import 'vue-easytable/libs/theme-default/index.css'
// 引入组件库
import VueEasytable from 'vue-easytable'
import CssLoading from '@/components/Loading/css-loading.vue'
import AppLoading from '@/components/Loading/app-loading.vue'
// 引入公共样式
import '@/assets/styles/index.scss'
import * as echarts from 'echarts'

// 将echarts挂载到Vue原型上
Vue.prototype.$echarts = echarts

// 注册公共组件
Vue.component('css-loading', CssLoading)
Vue.component('app-loading', AppLoading)

Vue.use(VueEasytable)
Vue.config.productionTip = false

new Vue({
  router, // this.$router===router
  store, // this.$store===store
  render: h => h(App)// vue支持的新写法
}).$mount('#app')

// const obj = {
//   name: 'kerwin',
//   age: 100
// }
// console.log(obj)

// 因为把代码格式检测关了，所以每次写完代码之后，
// 都要在终端执行命令 npm run lint来整理代码

// 也可以通过eslint插件修复，但是有时候不管用

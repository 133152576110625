<!-- eslint-disable vue/multi-word-component-names -->
<template>
<footer >
  <div class="box">
    <ul>
      <!-- vue-router3及以前 声明式导航 -->
        <!-- tag属性写成li可以不需要写外面的li，但是vue-router4会移除该属性 -->

        <!-- vue-router 4  声明式导航 -->
        <router-link to='/message' custom v-slot='{navigate,isActive}'>
          <li @click="navigate" :class="isActive?'active':'unactive'">
            <transition name="icon-transition" mode="out-in">
              <i v-if="!isActive" class="iconfont icon-comments inactive-icon"></i>
              <i v-else class="iconfont icon-color-filling active-icon"></i>
            </transition>
            <span>留言</span>
          </li>
        </router-link>
        <router-link to='/otherMessage' custom v-slot='{navigate,isActive}'>
          <li @click="navigate" :class="isActive?'active':'unactive'">
            <transition name="icon-transition" mode="out-in">
            <i v-if="!isActive" class="iconfont icon-email inactive-icon"></i>
            <i v-else class="iconfont icon-color-filling active-icon"></i>
            </transition>
            <span>匿名信</span>
          </li>
        </router-link>
        <router-link to='/model' custom v-slot='{navigate,isActive}'>
          <li @click="navigate" :class="isActive?'active':'unactive'">
            <transition name="icon-transition"  mode="out-in">
            <i v-if="!isActive" class="iconfont icon-help inactive-icon icon-center"></i>
            <i v-else class="iconfont icon-color-filling active-icon  icon-center"></i>
            </transition>
            <span>小言</span>
          </li>
        </router-link>
        <router-link to='/letter' custom v-slot='{navigate,isActive}'>
          <li @click="navigate" :class="isActive?'active':'unactive'">
            <transition name="icon-transition"  mode="out-in">
            <i v-if="!isActive" class="iconfont icon-form inactive-icon"></i>
            <i v-else class="iconfont icon-color-filling active-icon"></i>
            </transition>
            <span>公开信</span>
          </li>
        </router-link>

        <router-link to='/mineCenter' custom v-slot='{navigate,isActive}'>
          <li @click="navigate" :class="isActive?'active':'unactive'">
            <transition name="icon-transition"  mode="out-in">
            <i v-if="!isActive" class="iconfont icon-account inactive-icon"></i>
            <i v-else class="iconfont icon-color-filling active-icon"></i>
            </transition>
            <span>我的</span>
          </li>
        </router-link>
    </ul>
  </div>
</footer>
</template>

<script>
import '../assets/iconfont/iconfont.css'
export default {
  data () {
    return {
    }
  },
  created () {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
    footer{
         position: absolute;
         bottom: 0px;
         left: 0;
         width: calc(100vw);
         height: 3.622rem;
         background: rgb(239, 243, 242);
         z-index: 100;
        //  border-radius: 20px;
         padding-top: 8px;
         box-sizing: border-box;
        //  margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .box {
          width: calc(100vw - 20px);
          background-color: #fff;
          height: 3.222rem;
          border-radius: 10px;
          margin-bottom: 8px;
          padding-top: 8px;
          box-shadow: 0 0 3px rgb(129, 129, 129);
          box-sizing: border-box;
         ul{
          display: flex;
            li{
                flex: 1;
                line-height: 1.3889rem;
                text-align: center;
                display: flex;
                flex-direction: column;
                i{
                font-size: 1rem;
                font-weight: bold;
                }
                span{
                font-size: 0.5rem;
                font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                }
             }
         }
       }
    }
    .active{
      color: rgb(88, 170, 122);
    }
    .unactive {
      color: rgb(142, 158, 151)
    }
    .icon-transition-enter-active,
  .icon-transition-leave-active {
    transition: transform 0.5s ease-in;
  }
  .icon-transition-enter {
    transform: translateY(100%);
    opacity: 0;
  }
  .icon-transition-leave-to {
    transform: translateY(-100%);
    opacity: 0;
  }
  /* 自定义图标的动画效果 */
  .active-icon {
    animation: moveDown 0.8s ease-in;
  }
  .inactive-icon {
    animation: moveUp 0.8s ease-in;
  }
  @keyframes moveDown {
    // 0% {
    //   transform: translateY(-50%);
    //   opacity: 0.2;
    // }
    // 100% {
    //   transform: translateY(0);
    //   opacity: 1;
    // }
    0% {
      transform: scale(1) translateX(1%) translateY(1%);
      opacity: 0.1;
    }
    10% {
      transform: scale(1) translateX(0%) translateY(0%);
      opacity: 0.2;
    }
    20% {
      transform: scale(1) translateX(-1%) translateY(-1%);
      opacity: 0.3;
    }
    30% {
      transform: scale(1) translateX(0%) translateY(0%);
      opacity: 0.4;
    }
    40% {
      transform: scale(1) translateX(1%) translateY(1%);
      opacity: 0.5;
    }
    50% {
      transform: scale(1) translateX(0%) translateY(0%);
      opacity: 0.6;
    }
    60% {
      transform: scale(1) translateX(-1%) translateY(-1%);
      opacity: 0.7;
    }
    70% {
      transform: scale(1) translateX(0%) translateY(0%);
      opacity: 0.8;
    }
    80% {
      transform: scale(1) translateX(1%) translateY(1%);
      opacity: 0.9;
    }
    90% {
      transform: scale(1) translateX(0%) translateY(0%);
      opacity: 1;
    }
    100% {
      transform: scale(1) translateX(0%) translateY(0%);
      opacity: 1;
    }
  }
  @keyframes moveUp {
    0% {
      // transform: translateY(50%);
      opacity: 0.5;
    }
    100% {
      // transform: translateY(0);
      opacity: 1;
    }
    // 0% {
    //   transform: translateY(50%);
    //   opacity: 0.2;
    // }
    // 100% {
    //   transform: translateY(0);
    //   opacity: 1;
    // }
    // 0% {
    //   transform: scale(0.8);
    //   opacity: 0.2;
    // }
    // 25% {
    //   transform: scale(0.7);
    //   opacity: 0.4;
    // }
    // 50% {
    //   transform: scale(0.8);
    //   opacity: 0.6;
    // }
    // 75% {
    //   transform: scale(0.7);
    //   opacity: 0.8;
    // }
    // 100% {
    //   transform: translateY(0.8);
    //   opacity: 1;
    // }
  }
  .icon-center {
    font-size: 1.5rem!important;
    color: rgb(82, 168, 148);
  }
</style>
